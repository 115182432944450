const AnsweredCallIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='39' height='40' viewBox='0 0 39 40' fill='none'>
      <rect x='0.0499268' y='0.835449' width='38.3291' height='38.3291' rx='19.1646' fill='#C3FFAD' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.6963 17.5191C13.3563 16.8191 13.5893 16.6281 13.8713 16.5381C14.0659 16.4868 14.2702 16.4837 14.4663 16.5291C14.7243 16.6051 14.7923 16.6611 15.6383 17.5041C16.3813 18.2441 16.4553 18.3251 16.5273 18.4691C16.6637 18.7235 16.6848 19.0241 16.5853 19.2951C16.5123 19.5011 16.4033 19.6371 15.9533 20.0951L15.6593 20.3951C15.58 20.4743 15.5617 20.5961 15.6143 20.6951C16.2676 21.8095 17.1945 22.7389 18.3073 23.3951C18.4354 23.4635 18.5931 23.4413 18.6973 23.3401L18.9803 23.0621C19.155 22.8828 19.3399 22.7138 19.5343 22.5561C19.8364 22.3648 20.2158 22.3425 20.5383 22.4971C20.6953 22.5711 20.7473 22.6181 21.5143 23.3831C22.3053 24.1711 22.3273 24.1961 22.4143 24.3771C22.5793 24.6755 22.5793 25.0377 22.4143 25.3361C22.3293 25.5041 22.2773 25.5651 21.8333 26.0191C21.5643 26.2931 21.3123 26.5461 21.2723 26.5811C20.9046 26.8859 20.4324 27.0352 19.9563 26.9971C19.0873 26.9178 18.2463 26.6492 17.4923 26.2101C15.8222 25.3249 14.4006 24.0353 13.3573 22.4591C13.1292 22.1298 12.9263 21.7839 12.7503 21.4241C12.2812 20.6187 12.0393 19.7011 12.0503 18.7691C12.086 18.2817 12.3194 17.8301 12.6963 17.5191Z'
        stroke='#269200'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.3263 12.2471C21.9121 12.2471 21.5763 12.5829 21.5763 12.9971C21.5763 13.4113 21.9121 13.7471 22.3263 13.7471V12.2471ZM26.0383 13.7471C26.4525 13.7471 26.7883 13.4113 26.7883 12.9971C26.7883 12.5829 26.4525 12.2471 26.0383 12.2471V13.7471ZM26.7883 12.9971C26.7883 12.5829 26.4525 12.2471 26.0383 12.2471C25.6241 12.2471 25.2883 12.5829 25.2883 12.9971H26.7883ZM25.2883 16.4971C25.2883 16.9113 25.6241 17.2471 26.0383 17.2471C26.4525 17.2471 26.7883 16.9113 26.7883 16.4971H25.2883ZM26.5687 13.5274C26.8616 13.2345 26.8616 12.7596 26.5687 12.4667C26.2758 12.1738 25.8009 12.1738 25.508 12.4667L26.5687 13.5274ZM20.258 17.7167C19.9651 18.0096 19.9651 18.4845 20.258 18.7774C20.5509 19.0703 21.0258 19.0703 21.3187 18.7774L20.258 17.7167ZM22.3263 13.7471H26.0383V12.2471H22.3263V13.7471ZM25.2883 12.9971V16.4971H26.7883V12.9971H25.2883ZM25.508 12.4667L20.258 17.7167L21.3187 18.7774L26.5687 13.5274L25.508 12.4667Z'
        fill='#269200'
      />
    </svg>
  );
};

export default AnsweredCallIcon;
