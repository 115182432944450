const DeclinedCallIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='39' height='40' viewBox='0 0 39 40' fill='none'>
      <rect x='0.0499268' y='0.835449' width='38.3291' height='38.3291' rx='19.1646' fill='#FBD9CE' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.7181 16.5382C13.3986 15.8206 13.6396 15.6194 13.9293 15.5264C14.1301 15.4736 14.3408 15.4705 14.5431 15.5174C14.8093 15.595 14.8788 15.6537 15.7525 16.5228C16.5196 17.2864 16.5955 17.3694 16.6695 17.5156C16.8126 17.7788 16.8356 18.0908 16.7326 18.3721C16.6568 18.5842 16.5449 18.725 16.0801 19.1926L15.7768 19.4967C15.6971 19.5776 15.6781 19.7006 15.7299 19.8018C16.4031 20.9503 17.358 21.9083 18.5043 22.5852C18.6366 22.6561 18.7995 22.6331 18.9069 22.5284L19.1984 22.2414C19.3785 22.0565 19.5693 21.8823 19.7697 21.7197C20.084 21.5252 20.4763 21.5065 20.8076 21.67C20.9692 21.7459 21.0233 21.7955 21.8149 22.5843C22.6272 23.3966 22.6533 23.4228 22.7445 23.6096C22.9147 23.9191 22.913 24.2945 22.74 24.6024C22.6497 24.7757 22.5992 24.8389 22.1407 25.3073C21.8636 25.5907 21.5992 25.8488 21.5613 25.8867C21.1826 26.1997 20.6972 26.3534 20.2074 26.3155C19.3114 26.2334 18.4442 25.9562 17.6668 25.5032C15.9437 24.5902 14.4769 23.2599 13.4004 21.6339C13.1651 21.2943 12.9557 20.9374 12.7741 20.5662C12.2901 19.7365 12.0399 18.7911 12.0502 17.8306C12.0866 17.3266 12.328 16.8594 12.7181 16.5382Z'
        stroke='#DB0000'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.5953 13.1487C21.3024 12.8558 20.8275 12.8558 20.5346 13.1487C20.2417 13.4415 20.2417 13.9164 20.5346 14.2093L21.5953 13.1487ZM22.3397 16.0144C22.6326 16.3073 23.1075 16.3073 23.4004 16.0144C23.6933 15.7215 23.6933 15.2466 23.4004 14.9537L22.3397 16.0144ZM23.4004 14.9537C23.1075 14.6608 22.6326 14.6608 22.3397 14.9537C22.0468 15.2466 22.0468 15.7215 22.3397 16.0144L23.4004 14.9537ZM24.1448 17.8195C24.4377 18.1124 24.9126 18.1124 25.2055 17.8195C25.4983 17.5266 25.4983 17.0517 25.2055 16.7588L24.1448 17.8195ZM23.4004 16.0144C23.6933 15.7215 23.6933 15.2466 23.4004 14.9537C23.1075 14.6608 22.6326 14.6608 22.3397 14.9537L23.4004 16.0144ZM20.5346 16.7588C20.2417 17.0517 20.2417 17.5266 20.5346 17.8195C20.8275 18.1124 21.3024 18.1124 21.5953 17.8195L20.5346 16.7588ZM22.3397 14.9537C22.0468 15.2466 22.0468 15.7215 22.3397 16.0144C22.6326 16.3073 23.1075 16.3073 23.4004 16.0144L22.3397 14.9537ZM25.2055 14.2093C25.4983 13.9164 25.4983 13.4415 25.2055 13.1486C24.9126 12.8557 24.4377 12.8557 24.1448 13.1486L25.2055 14.2093ZM20.5346 14.2093L22.3397 16.0144L23.4004 14.9537L21.5953 13.1487L20.5346 14.2093ZM22.3397 16.0144L24.1448 17.8195L25.2055 16.7588L23.4004 14.9537L22.3397 16.0144ZM22.3397 14.9537L20.5346 16.7588L21.5953 17.8195L23.4004 16.0144L22.3397 14.9537ZM23.4004 16.0144L25.2055 14.2093L24.1448 13.1486L22.3397 14.9537L23.4004 16.0144Z'
        fill='#DB0000'
      />
    </svg>
  );
};

export default DeclinedCallIcon;
