import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from 'apps/webapp/public/locales/en.json';
import translationAR from 'apps/webapp/public/locales/ar.json';
import { safeLocalStorage } from 'apps/webapp/src/hooks/useLocalStorage';

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
};

const locallyStoredLanguage = JSON.parse(safeLocalStorage?.getItem('settings') || '{}')?.language;

i18n

  // // Enables the i18next backend
  // .use(Backend)

  // Enable automatic language detection
  .use(LanguageDetector)

  // Enables the hook initialization module
  .use(initReactI18next)
  .init({
    resources,
    lng: locallyStoredLanguage || 'en',
    fallbackLng: 'en',
    debug: false,
    keySeparator: false,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
  });

export default i18n;
