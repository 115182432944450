import { PGliteWorker } from '@electric-sql/pglite/worker';
import { DB_NAME } from './utils';

let dbWorker: PGliteWorker | null = null;

export async function getWorker(): Promise<PGliteWorker> {
  if (dbWorker) return dbWorker;

  console.debug('[DevTools] Initializing database worker...');

  dbWorker = await PGliteWorker.create(new Worker(new URL('./pglite.worker.ts', import.meta.url), { type: 'module' }), {
    dataDir: DB_NAME,
  });

  dbWorker.onLeaderChange(() => {
    console.debug('[DevTools] Database leader changed, isLeader:', dbWorker?.isLeader);
  });

  return dbWorker;
}
