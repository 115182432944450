// ** React Imports
import { Fragment, SyntheticEvent, useState } from 'react';

// ** Next Import
import { useRouter } from 'next/router';

// ** MUI Imports
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import CustomAvatar from 'apps/webapp/src/@core/components/mui/avatar';

// ** Icon Imports
import Icon from 'apps/webapp/src/@core/components/icon';

// ** Context
import { useAuth } from 'apps/webapp/src/hooks/useAuth';

// ** Type Imports
import { getUserFullName } from '@finance-ops/types';
import { Button, useTheme } from '@mui/material';
import { Settings } from 'apps/webapp/src/@core/context/settingsContext';
import { getInitials } from '../../../utils/get-initials';

interface Props {
  settings: Settings;
  navHover?: boolean;
}

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));

const MenuItemStyled = styled(MenuItem)<MenuItemProps>(({ theme }) => ({
  '&:hover .MuiBox-root, &:hover .MuiBox-root svg': {
    color: theme.palette.primary.main,
  },
}));

const UserDropdown = (props: Props) => {
  // ** Props
  const { settings, navHover } = props;
  const theme = useTheme();

  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  // ** Hooks
  const router = useRouter();
  const { logout, user } = useAuth();

  // ** Vars
  const { direction } = settings;

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const titleStyles = {
    box: { display: 'flex', alignItems: 'center', px: 5, width: '100%' },
    typography: { ml: 2, fontSize: '0.8rem', color: theme.palette.text.primary, fontWeight: 600 },
  };

  const handleDropdownClose = (url?: string) => {
    if (url) {
      router.push(url);
    }
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleDropdownClose();
  };

  return (
    <Fragment>
      <Button
        onClick={handleDropdownOpen}
        sx={{
          m: 0,
          p: 2,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: theme.palette.grey[100],
          },
        }}
      >
        <Badge
          overlap='circular'
          sx={{ cursor: 'pointer' }}
          badgeContent={<BadgeContentSpan />}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <CustomAvatar
            skin={'light-static'}
            sx={{
              width: 40,
              height: 40,
              fontSize: '1.1rem',
              outline: theme => `2px solid ${theme.palette.common.white}`,
            }}
          >
            {user && getInitials(user.firstName + user.lastName)}
          </CustomAvatar>
        </Badge>
        {navHover && (
          <Typography sx={titleStyles.typography}>
            {user?.firstName} {user?.lastName}
          </Typography>
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 230, mt: 4.5 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
      >
        <Box sx={{ py: 1.75, px: 6 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <MenuItemStyled onClick={() => handleDropdownClose('/profile')}>
              <Badge
                overlap='circular'
                badgeContent={<BadgeContentSpan />}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <CustomAvatar
                  skin={'light-static'}
                  sx={{
                    width: 40,
                    height: 40,
                    fontSize: '1.1rem',
                    outline: theme => `2px solid ${theme.palette.common.white}`,
                  }}
                >
                  {user && getInitials(user.firstName + user.lastName)}
                </CustomAvatar>
              </Badge>
              <Box sx={{ display: 'flex', ml: 2.5, alignItems: 'flex-start', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 500 }}>{getUserFullName(user)}</Typography>
                <Typography variant='body2'>{user?.role?.name.split('_').join(' ')}</Typography>
              </Box>
            </MenuItemStyled>
          </Box>
        </Box>
        {/* <MenuItemStyled sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={styles}>
            <Icon icon='tabler:settings' />
            Settings
          </Box>
        </MenuItemStyled> */}
        <Divider sx={{ my: theme => `${theme.spacing(2)} !important` }} />
        <MenuItemStyled onClick={handleLogout} sx={{ py: 2, '& svg': { mr: 2, fontSize: '1.375rem' } }}>
          <Icon icon='tabler:logout' />
          Logout
        </MenuItemStyled>
      </Menu>
    </Fragment>
  );
};

export default UserDropdown;
