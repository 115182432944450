export const enum SortOrder {
  ASC = 1,
  DESC = -1,
  NONE = 0,
}

export enum SortLabel {
  name = 'Name',
  amount = 'Due Amount',
  DPD = 'Days Past Due',
  inboundText = 'Received Text',
  outboundText = 'Sent Text',
  inboundEmail = 'Received Email',
  outboundEmail = 'Sent Email',
  lastUpdated = 'Last Updated',
  worklistRank = 'Worklist Rank',
  lastMessageDate = 'Last Contact',
}

export enum SortLabelIcon {
  name = 'material-symbols:person',
  amount = 'ic:baseline-attach-money',
  DPD = 'tabler:calendar-due',
  inboundText = 'mage:message-dots-round-download',
  outboundText = 'mage:message-dots-round-upload',
  inboundEmail = 'icon-park-outline:email-down',
  outboundEmail = 'icon-park-outline:email-push',
  lastMessageDate = 'tabler:message-circle-dots',
  lastUpdated = 'radix-icons:update',
  worklistRank = 'material-symbols:list-alt',
}

export enum SortField {
  name = 'names.0',
  amount = 'totalDueAmount',
  DPD = 'invoices.0.dueDate',
  inboundText = 'task.lastInboundMessageDate',
  outboundText = 'task.lastOutboundMessageDate',
  inboundEmail = 'task.lastInboundEmailDate',
  outboundEmail = 'task.lastOutboundEmailDate',
  lastUpdated = 'task.updatedAt',
  lastMessageDate = 'task.lastMessageDate',
  worklistRank = 'task.worklistRank',
}

export interface Sort {
  label: SortLabel;
  fieldName: SortField;
  isActive: boolean;
  order: SortOrder;
  icon: SortLabelIcon;
}

export type SortType = {
  [key in SortField]?: SortOrder;
};
