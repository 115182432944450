import { ClientType } from '@finance-ops/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { clientSlice } from '../client';
import { baseQuery } from './baseQuery';

export const clientsApi = createApi({
  reducerPath: 'clientsApi',
  baseQuery: baseQuery,
  tagTypes: ['clients'],
  endpoints: builder => ({
    getClients: builder.query<ClientType[], void>({
      query: () => '/clients',
      providesTags: ['clients'],
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled;
        dispatch(clientSlice.actions.upsertManyClients(result.data));
      },
    }),
    clientAssignUsers: builder.mutation<ClientType, { clientId: string; userIds: string[] }>({
      query: ({ clientId, userIds }) => ({
        url: `/clients/${clientId}/assignUsers`,
        method: 'PATCH',
        body: { assignedUserIds: userIds },
      }),
      invalidatesTags: (result, error, { clientId }) => [{ type: 'clients', id: clientId }],
      async onQueryStarted({ clientId, userIds }, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled;
        dispatch(clientSlice.actions.upsertOneClient(result.data));
      },
    }),
    updateClient: builder.mutation<ClientType, { clientId: string; data: Partial<ClientType> }>({
      query: ({ clientId, data }) => ({
        url: `/clients/${clientId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, error, { clientId }) => [{ type: 'clients', id: clientId }],
      async onQueryStarted({ clientId, data }, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled;
        dispatch(clientSlice.actions.upsertOneClient(result.data));
      },
    }),
  }),
});

export const { useClientAssignUsersMutation, useGetClientsQuery, useUpdateClientMutation } = clientsApi;
