import { DeviceInfoType } from '@finance-ops/types';
import { fetchIpData } from '../ip-api';

function detectBrowser(): string {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes('firefox')) return 'Firefox';
  if (userAgent.includes('edg')) return 'Edge';
  if (userAgent.includes('chrome')) return 'Chrome';
  if (userAgent.includes('safari')) return 'Safari';
  if (userAgent.includes('opera') || userAgent.includes('opr')) return 'Opera';

  return 'Unknown browser';
}

async function detectPlatform(): Promise<string> {
  return new Promise(resolve => {
    if ('userAgentData' in navigator && navigator.userAgentData) {
      (navigator.userAgentData as any)
        .getHighEntropyValues(['platform'])
        .then((data: { platform: string }) => {
          resolve(data.platform || 'Unknown platform');
        })
        .catch(() => {
          resolve('Unknown platform');
        });
    } else {
      const userAgent = navigator.userAgent;
      if (/Windows/.test(userAgent)) resolve('Windows');
      else if (/Mac/.test(userAgent)) resolve('Mac');
      else if (/Linux/.test(userAgent)) resolve('Linux');
      else if (/Android/.test(userAgent)) resolve('Android');
      else if (/iPhone|iPad|iPod/.test(userAgent)) resolve('iOS');
      else resolve('Unknown platform');
    }
  });
}

async function getDeviceInfo(): Promise<DeviceInfoType> {
  try {
    const [platform, locationData] = await Promise.all([
      detectPlatform(),
      fetchIpData(['ip', 'city', 'region', 'country_name']),
    ]);

    return {
      browser: detectBrowser(),
      os: platform,
      ip: locationData.ip,
      city: locationData.city,
      region: locationData.region,
      country_name: locationData.country_name,
    };
  } catch (error) {
    console.dir('Error getting device info:', error);
    return {
      browser: detectBrowser(),
      os: await detectPlatform(),
    };
  }
}

export { getDeviceInfo };
