import { DeviceInfoType, PERMISSION_SUBJECT, UI_MODE, UserType, WebSocketMessageType } from '@finance-ops/types';
import authConfig from 'apps/webapp/src/configs/auth';
import axios from 'axios';
import { useRouter } from 'next/router';
import { ReactNode, createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { initiateLogRocket } from '../@core/thirdParty/logRocket';
import useLocalStorageDashboardState from '../hooks/dashboard';
import { useGetRoles } from '../hooks/roles';
import { useGetUsers } from '../hooks/users';
import { httpGet, setHttpToken } from '../httpClient';
import { AppDispatch } from '../store';
import { resetActions } from '../store/action';
import { resetClients, setSelectedClientId } from '../store/client';
import { resetCustomerContext as resetCustomerContextCustomerPage } from '../store/customerPageCContext';
import { resetCustomerContext, setAssignedAgentId } from '../store/customercontext';
import { setUIMode } from '../store/dashboard';
import { resetInvoices } from '../store/invoice';
import { resetNotifications } from '../store/notification';
import { resetUsers, setDeviceVerification } from '../store/users';
import { getDeviceInfo } from '../utils/device-info';
import { disconnectWebsocket, sendMessage, setupWebsocket } from '../websocket/WebSocketClient';
import { AuthValuesType, ErrCallbackType, GoogleLoginParams, LoginParams, RegisterParams } from './types';
import { safeLocalStorage } from 'apps/webapp/src/hooks/useLocalStorage';

const defaultProvider: AuthValuesType = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  otpLogin: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  googleLogin: () => Promise.resolve(),
};

const AuthContext = createContext(defaultProvider);

type Props = {
  children: ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  // ** States
  const [user, setUser] = useState<UserType | null>(defaultProvider.user);
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading);

  // ** Hooks
  const router = useRouter();

  const { getUsers } = useGetUsers();
  const { getRoles } = useGetRoles();

  const { resetLocastorageDashboardState } = useLocalStorageDashboardState();

  const getDashboard = (isInternal: boolean) => {
    dispatch(setUIMode(isInternal ? UI_MODE.ALL : UI_MODE.AUTOPILOT));
  };

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    resetLocastorageDashboardState();
    if (user) {
      initiateLogRocket(user);
    }
  }, [user]);

  const onSocketConnectedCallback = () => {
    sendMessage(WebSocketMessageType.CLIENT_GET_ALL);
  };

  const setClientIdAndAssignedAgentId = (user: UserType) => {
    const isCollectionAgent = user.role?.permissions?.some(
      permission =>
        permission.subject === PERMISSION_SUBJECT.COLLECTION_AGENT_EXTERNAL ||
        permission.subject === PERMISSION_SUBJECT.COLLECTION_AGENT_INTERNAL,
    );
    if (isCollectionAgent) {
      dispatch(setAssignedAgentId(user.id));
    }
    const isUserExternal = !user.role?.isInternal;
    if (isUserExternal) {
      dispatch(setSelectedClientId(user.customerId));
    }
    if (user.role?.isSuperAdmin) {
      dispatch(setSelectedClientId(null));
      dispatch(setAssignedAgentId(null));
    }
  };

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      // For login with google, in which we receive a redirect url with "token"
      const urlParams = new URLSearchParams(window.location.search);
      const urlToken = urlParams.get('token');
      if (urlToken) {
        safeLocalStorage.setItem(authConfig.storageTokenKeyName, urlToken);
      }
      const storedToken = safeLocalStorage.getItem(authConfig.storageTokenKeyName)!;
      if (storedToken) {
        setLoading(true);
        setHttpToken(storedToken);
        await httpGet(
          process.env.NEXT_PUBLIC_BASE_URL + authConfig.meEndpoint,
          {},
          {
            'device-id': await getDeviceId(),
          },
        )
          .then(async response => {
            setLoading(false);
            if (router.pathname === '/login' || router.pathname === '/') {
              router.replace('/');
            }
            setupWebsocket(onSocketConnectedCallback);
            setUser({ ...response });
            // get all the users and roles, this is used to display the name of assigned agent in customers, chat page and other use cases in other
            // pages
            getUsers();
            getRoles();
            setClientIdAndAssignedAgentId(response);
            getDashboard(response?.role?.isInternal ?? false);

            safeLocalStorage.setItem('user', JSON.stringify(response));
          })
          .catch(e => {
            safeLocalStorage.removeItem('user');
            safeLocalStorage.removeItem('refreshToken');
            safeLocalStorage.removeItem('accessToken');
            setUser(null);
            setLoading(false);
            setHttpToken('');
            if (authConfig.onTokenExpiration === 'logout' && !router.pathname.includes('login')) {
              router.replace('/login');
            }
          });
      } else {
        setLoading(false);
      }
    };

    initAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDeviceId = async () => {
    const deviceId = safeLocalStorage.getItem('device-id');
    return deviceId || '';
  };

  const setDeviceId = (deviceId: string) => {
    safeLocalStorage.setItem('device-id', deviceId);
  };

  const handleLogin = async (params: LoginParams, errorCallback?: any) => {
    try {
      const deviceId = await getDeviceId();
      const deviceInfo: DeviceInfoType = await getDeviceInfo();

      const response = await axios.post(process.env.NEXT_PUBLIC_BASE_URL + authConfig.loginEndpoint, {
        ...params,
        deviceId,
        deviceInfo,
      });

      if (response.data.requiresDeviceVerification) {
        dispatch(
          setDeviceVerification({
            show: true,
            deviceId: response.data.deviceId,
            userId: response.data.userId,
            cooldownEndTime: response.data.cooldownEndTime,
          }),
        );

        setDeviceId(response.data.deviceId);
        return;
      }

      // Reset device verification state
      dispatch(setDeviceVerification({ show: false }));

      // Normal login flow
      handleLoginSuccess(response.data, params);
    } catch (err) {
      if (errorCallback) errorCallback(err as { [key: string]: string });
    }
  };

  const handleLoginSuccess = (data: any, params: LoginParams) => {
    // Handle login success
    const returnUrl = router.query.returnUrl;
    setHttpToken(data.accessToken);
    setUser(data.user);
    // get all the users and roles, this is used to display the name of assigned agent in customers, chat page and other use cases in other
    // pages
    getUsers();
    getRoles();
    setClientIdAndAssignedAgentId(data.user);
    getDashboard(data?.user?.role?.isInternal ?? false);
    params.rememberMe ? safeLocalStorage.setItem('user', JSON.stringify(data.user)) : null;
    params.rememberMe ? safeLocalStorage.setItem(authConfig.storageTokenKeyName, data.accessToken) : null;
    const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/';
    setupWebsocket(onSocketConnectedCallback);
    router.replace(redirectURL as string);
  };

  const googleLogin = (params: GoogleLoginParams, errorCallback?: ErrCallbackType) => {
    axios
      .post(process.env.NEXT_PUBLIC_BASE_URL + authConfig.googleLoginEndpoint, params)
      .then(async response => {
        safeLocalStorage.setItem(authConfig.storageTokenKeyName, response.data.accessToken);
        const returnUrl = router.query.returnUrl;

        setHttpToken(response.data.accessToken);
        setUser(response.data.user);
        safeLocalStorage.setItem('user', JSON.stringify(response.data.user));

        const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/';

        setupWebsocket();
        router.replace(redirectURL as string);
      })

      .catch(err => {
        if (errorCallback) errorCallback(err);
      });
  };

  const handleOTPLogin = (token: string) => {
    safeLocalStorage.setItem(authConfig.storageTokenKeyName, token);
    setHttpToken(token);
  };

  const handleLogout = () => {
    disconnectWebsocket();
    dispatch(resetClients());
    dispatch(resetCustomerContext());
    dispatch(resetCustomerContextCustomerPage());
    dispatch(resetUsers());
    dispatch(resetInvoices());
    dispatch(resetActions());
    dispatch(resetNotifications());
    resetLocastorageDashboardState();
    setHttpToken('');
    setUser(null);
    safeLocalStorage.removeItem('user');
    safeLocalStorage.removeItem(authConfig.storageTokenKeyName);
    router.push('/login');
    dispatch(setSelectedClientId(null));
  };

  const handleRegister = (params: RegisterParams, errorCallback?: ErrCallbackType) => {
    axios
      .post(authConfig.registerEndpoint, params)
      .then(res => {
        if (res.data.error) {
          if (errorCallback) errorCallback(res.data.error);
        } else {
          handleLogin({ username: params.email, password: params.password });
        }
      })
      .catch((err: { [key: string]: string }) => (errorCallback ? errorCallback(err) : null));
  };

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    login: handleLogin,
    otpLogin: handleOTPLogin,
    logout: handleLogout,
    register: handleRegister,
    googleLogin,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
