import { safeLocalStorage } from 'apps/webapp/src/hooks/useLocalStorage';

const isBrowser = typeof window !== 'undefined';

export const deleteAllCookies = () => {
  if (!isBrowser) return;
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    document.cookie = cookie + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
  }
};

export const deleteAllLocalStorage = () => {
  if (!isBrowser) return;
  safeLocalStorage.clear();
};

export const deleteAllSessionStorage = () => {
  if (!isBrowser) return;
  sessionStorage.clear();
};

export const hardLogout = () => {
  if (!isBrowser) return;
  deleteAllCookies();
  deleteAllLocalStorage();
  deleteAllSessionStorage();
  window.location.reload();
};
