import {
  ACTION_CENTER_ACTION_TYPES,
  ACTION_CENTER_STATUS_TYPES,
  ACTION_TYPES,
  ActionCenterActionType,
  FOLLOW_UP_ACTION_TYPE,
  P2P_ACTION_TYPES,
  PRIORITY_TYPES,
} from '@finance-ops/constants';
import { CommonType, GenericObject } from '../common';
import { CustomerType } from '../customer';
import { CustomerContextType } from '../customer-context';

export type ActionType = CommonType & {
  id: string;
  clientId: string;
  customerId: string;
  taskId: string;
  communicationId: string | null;
  type: ACTION_TYPES;
  aiEntity: GenericObject | null;
} & (
    | {
        type: ActionCenterActionType;
        userEntity: UserEntityActionCenterType;
      }
    | {
        type: ACTION_TYPES.P2P;
        userEntity: UserEntityP2PType;
      }
    | {
        type: ACTION_TYPES.FOLLOW_UP;
        userEntity: UserEntityFollowUpType;
      }
    | {
        type: ACTION_TYPES.INVOICE_STATUS;
        userEntity: UserEntityInvoiceStatusType;
      }
    | {
        type: ACTION_TYPES.CASE_TRANSFER;
        userEntity: UserEntityCaseTransferType;
      }
    | {
        userEntity: null;
      }
  );

export type BaseActionType = CommonType & {
  id: string;
  clientId: string;
  customerId: string;
  taskId: string;
  communicationId: string | null;
  type: ACTION_TYPES;
  aiEntity: GenericObject | null;
  userEntity: UserEntityType;
};

export type ActionsExtensionType = ActionType & {
  customer: CustomerType;
  cc: CustomerContextType;
};

export type UserEntityType =
  | UserEntityActionCenterType
  | UserEntityP2PType
  | UserEntityFollowUpType
  | UserEntityInvoiceStatusType
  | UserEntityCaseTransferType
  | null;

export type UserEntityActionCenterType = {
  subject: string;
  desc: string;
  priority: PRIORITY_TYPES;
  status: ACTION_CENTER_STATUS_TYPES;
  issuerId: string;
  assignedAgent?: {
    id: string;
    name: string;
  };
  closedTimestamp?: Date;
};

export type UserEntityP2PType = {
  amount?: number;
  date?: Date;
  dateInStringWithoutTime?: string;
  for?: string;
  reason: string;
  invoiceIds: string[];
  status: P2P_ACTION_TYPES;
  paidDate?: Date;
  paidAmount?: number;
  paymentPlanId?: string;
};

export type UserEntityFollowUpType = {
  date?: Date;
  dateInStringWithoutTime?: string;
  reason: string;
  for: string;
  note: string;
  invoiceIds: string[];
  status?: FOLLOW_UP_ACTION_TYPE;
};

export type UserEntityInvoiceStatusType = {
  status: string[];
  invoiceId: string;
};

export type UserEntityCaseTransferType = {
  customerId: string;
  customerName: string;
  assignedAgentId: string;
  assignedAgentName: string;
  requestedAgentId: string | null;
  requestedAgentName: string;
  reason: string;
  status: 'Approved' | 'Rejected' | null;
};

export function isActionTypeActionCenter(action?: ActionType | null): action is ActionType & {
  type: ActionCenterActionType;
  userEntity: UserEntityActionCenterType;
} {
  return Boolean(action && 'type' in action && ACTION_CENTER_ACTION_TYPES.has(action.type));
}

export function isActionTypeP2PAction(action?: ActionType | null): action is ActionType & {
  type: ACTION_TYPES.P2P;
  userEntity: UserEntityP2PType;
} {
  return Boolean(action?.type === ACTION_TYPES.P2P);
}

export function isActionTypeFollowUp(action?: ActionType | null): action is ActionType & {
  type: ACTION_TYPES.FOLLOW_UP;
  userEntity: UserEntityFollowUpType;
} {
  return Boolean(action?.type === ACTION_TYPES.FOLLOW_UP);
}

export function isActionTypeInvoiceStatus(action?: ActionType | null): action is ActionType & {
  type: ACTION_TYPES.INVOICE_STATUS;
  userEntity: UserEntityInvoiceStatusType;
} {
  return Boolean(action?.type === ACTION_TYPES.INVOICE_STATUS);
}

export function isActionTypeCaseTransfer(action?: ActionType | null): action is ActionType & {
  type: ACTION_TYPES.CASE_TRANSFER;
  userEntity: UserEntityCaseTransferType;
} {
  return Boolean(action?.type === ACTION_TYPES.CASE_TRANSFER);
}

export function isActionTypeGeneric(action?: ActionType | null): action is ActionType & {
  userEntity: GenericObject;
} {
  return Boolean(
    action?.userEntity !== null &&
      !isActionTypeActionCenter(action) &&
      !isActionTypeP2PAction(action) &&
      !isActionTypeFollowUp(action) &&
      !isActionTypeInvoiceStatus(action) &&
      !isActionTypeCaseTransfer(action),
  );
}

export function isActionTypeNull(action?: ActionType | null): action is ActionType & {
  userEntity: null;
} {
  return Boolean(action?.userEntity === null);
}
