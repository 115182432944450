import {
  CustomerContext,
  CustomerContextGetAllRequest,
  CustomerContextGetCountRequest,
  TASK_STATE,
  TaskType,
} from '@finance-ops/types';
import { GridFilterItem } from '@mui/x-data-grid';
import { EntityState, PayloadAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { AllCustomersFilters, CustomerFilters, P2PFilters, PaymentFilters } from '../types/Filters';
import { ActiveTaskSection, TaskDrawerSections } from '../types/activeDrawerSection';
import { AmountGraphData } from '../types/amountGraphData';

const defaultCustomerFiltersState: CustomerFilters = {
  allCustomers: {
    mode: { autoPilot: false, coPilot: false },
    agingGroup: { optionOne: false, optionTwo: false, optionThree: false, optionFour: false, optionFive: false },
    totalDueRange: [200, 600],
  },
  p2p: {
    status: {
      open: false,
      closed: false,
    },
  },
  payments: {
    paymentMethod: {
      stripe: false,
      check: false,
      cash: false,
      wire: false,
    },
    amount: [0, 1000],
  },
};

const defaultClientPaginationState = {
  page: 0,
  pageSize: 100,
};

interface CustomerContextPaginationType {
  page: number;
  pageSize: number;
  clientId?: string;
}

interface ActiveTaskDrawer {
  isOpen: boolean;
  activeSection?: ActiveTaskSection;
}

const defaultActiveTaskDrawerState: ActiveTaskDrawer = {
  isOpen: false,
  activeSection: TaskDrawerSections.details,
};

interface CustomerContextStateItem {
  selectedTaskId?: string;
  selectedCustomerId?: string;
  selectedCustomerContextId?: string;
  hydratedIds: string[];
  fetched: boolean;
  customerPageCContextLoading: boolean;
  paginationLoading: boolean;
  filter: TASK_STATE;
  pagination: { [key: string]: CustomerContextPaginationType };
  totalCustomerContextCount: number;
  customerSearchQuery?: string;
  paginationKey: string | null;
  clientPagination: CustomerContextPaginationType;
  invoiceQueries: GridFilterItem | null;
  selectedCustomers: string[];
  customerFilters: CustomerFilters;
  activeTaskDrawer: ActiveTaskDrawer;
  selectedTasks: string[];
  amountGraphData: AmountGraphData[];
  selectedTasksForChat: string[];
  customerContextGetRequest?: CustomerContextGetAllRequest;
  customerContextGetCountRequest?: CustomerContextGetCountRequest;
}

interface CustomerContextState extends EntityState<CustomerContext>, CustomerContextStateItem {}

const customerPageCContextEntityAdapter = createEntityAdapter<CustomerContext>({
  selectId: entity => entity.task.id,
});

const initialState: CustomerContextState = {
  ...customerPageCContextEntityAdapter.getInitialState(),
  selectedTaskId: undefined,
  selectedCustomerId: undefined,
  selectedCustomerContextId: undefined,
  hydratedIds: [],
  fetched: false,
  customerPageCContextLoading: true,
  paginationLoading: false,
  filter: TASK_STATE.ASSIGNED,
  pagination: {},
  totalCustomerContextCount: 0,
  customerSearchQuery: undefined,
  paginationKey: null,
  clientPagination: defaultClientPaginationState,
  invoiceQueries: null,
  selectedCustomers: [],
  customerFilters: defaultCustomerFiltersState,
  activeTaskDrawer: defaultActiveTaskDrawerState,
  selectedTasks: [],
  amountGraphData: [],
  selectedTasksForChat: [],
};

export const customerPageCContextSlice = createSlice({
  name: 'customerPageCContext',
  initialState,
  reducers: {
    updateManyCustomerContexts: (state, action: PayloadAction<CustomerContext[]>) => {
      const updates = action.payload.map(entity => ({
        id: entity.task.id,
        changes: entity,
      }));
      customerPageCContextEntityAdapter.updateMany(state, updates);
    },
    upsertOneCustomerContext: (state, action) => {
      customerPageCContextEntityAdapter.upsertOne(state, action.payload);
    },
    updateOneCustomerContext: (state, action) => {
      customerPageCContextEntityAdapter.updateOne(state, {
        id: action.payload.task.id,
        changes: action.payload,
      });
    },
    upsertManyCustomerContexts: (state, action) => {
      customerPageCContextEntityAdapter.upsertMany(state, action.payload);
      state.customerPageCContextLoading = false;
    },
    setAllCustomerContexts: (state, action) => {
      customerPageCContextEntityAdapter.setAll(state, action.payload);
      state.customerPageCContextLoading = false;
    },
    resetCustomerContext: () => {
      return initialState;
    },
    setSelectedCustomerId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedCustomerId = action.payload;
    },
    setSelectedCustomerContextId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedCustomerContextId = action.payload;
    },
    setSelectedTaskId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedTaskId = action.payload;
    },
    setCustomerSearchQuery: (state, action: PayloadAction<string>) => {
      state.customerSearchQuery = action.payload;
    },
    setCustomerContextCount: (state, action: PayloadAction<number>) => {
      state.totalCustomerContextCount = action.payload;
    },
    setCustomerContextPaginationLoading: (state, action: PayloadAction<boolean>) => {
      state.customerPageCContextLoading = action.payload;
    },
    setCustomerPagination: (state, action: PayloadAction<CustomerContextPaginationType>) => {
      state.clientPagination = action.payload;
    },
    setHydratedCustomerContextIds: (state, action: PayloadAction<string[]>) => {
      state.hydratedIds = state.hydratedIds.concat(action.payload);
    },
    upsertCustomerContextTask: (state, action: PayloadAction<TaskType>) => {
      const taskId = action.payload.id;
      const contextId = Object.keys(state.entities).find(id => state.entities[id]?.task.id === taskId);

      if (contextId) {
        customerPageCContextEntityAdapter.updateOne(state, {
          id: contextId,
          changes: { task: action.payload },
        });
      }
    },
    setSelectedCustomers: (state, action: PayloadAction<string[]>) => {
      state.selectedCustomers = action.payload;
    },
    setAllCustomerFilters: (state, action: PayloadAction<AllCustomersFilters>) => {
      state.customerFilters = { ...state.customerFilters, allCustomers: action.payload };
    },
    setP2PFilters: (state, action: PayloadAction<P2PFilters>) => {
      state.customerFilters = { ...state.customerFilters, p2p: action.payload };
    },
    setPaymentsFilters: (state, action: PayloadAction<PaymentFilters>) => {
      state.customerFilters = { ...state.customerFilters, payments: action.payload };
    },
    setActiveTaskDrawer: (state, action: PayloadAction<Partial<ActiveTaskDrawer>>) => {
      state.activeTaskDrawer = { ...state.activeTaskDrawer, ...action.payload };
    },
    setSelectedTasks: (state, action: PayloadAction<string[]>) => {
      state.selectedTasks = action.payload;
    },
    setAmountGraphData: (state, action: PayloadAction<AmountGraphData[]>) => {
      state.amountGraphData = action.payload;
    },
    setCustomerContextGetRequest: (state, action: PayloadAction<CustomerContextGetAllRequest>) => {
      state.customerContextGetRequest = action.payload;
    },
    setCustomerContextGetCountRequest: (state, action: PayloadAction<CustomerContextGetCountRequest>) => {
      state.customerContextGetCountRequest = action.payload;
    },
  },
});

export const customerPageCContextSelectors = customerPageCContextEntityAdapter.getSelectors<RootState>(
  state => state.customerPageCContext,
);

export const selectedCustomerContext = createSelector(
  (state: RootState) => state.customerPageCContext.entities,
  (state: RootState) => state.customerPageCContext.selectedCustomerContextId,
  (entities, selectedCustomerContextId) => {
    if (!selectedCustomerContextId) return undefined;
    return entities[selectedCustomerContextId];
  },
);

export const selectSelectedCustomer = createSelector(
  (state: RootState) => state.customerPageCContext.entities,
  (state: RootState) => state.customerPageCContext.selectedCustomerContextId,
  (state: RootState) => state.customerPageCContext.selectedCustomerId,
  (entities, selectedCustomerContextId, selectedCustomerId) => {
    if (selectedCustomerContextId && selectedCustomerId)
      return entities[selectedCustomerContextId]?.customers.find(c => c._id.toString() === selectedCustomerId);
    return undefined;
  },
);

export const {
  resetCustomerContext,
  setActiveTaskDrawer,
  setAllCustomerContexts,
  setAllCustomerFilters,
  setAmountGraphData,
  setCustomerContextCount,
  setCustomerContextGetCountRequest,
  setCustomerContextGetRequest,
  setCustomerContextPaginationLoading,
  setCustomerPagination,
  setCustomerSearchQuery,
  setHydratedCustomerContextIds,
  setP2PFilters,
  setPaymentsFilters,
  setSelectedCustomerContextId,
  setSelectedCustomerId,
  setSelectedCustomers,
  setSelectedTasks,
  updateManyCustomerContexts,
  updateOneCustomerContext,
  upsertCustomerContextTask,
  upsertManyCustomerContexts,
  upsertOneCustomerContext,
} = customerPageCContextSlice.actions;

export default customerPageCContextSlice.reducer;
