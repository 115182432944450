import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum StrategyViews {
  LANDING = 'LANDING',
  CUSTOMER_SEGMENT = 'CUSTOMER_SEGMENT',
  ACTIONS = 'ACTIONS',
  ALLOCATE_STRATEGY = 'ALLOCATE_STRATEGY',
  DASHBOARD = 'DASHBOARD',
  ACTIONS_COMPARISION = 'ACTIONS_COMPARISION',
  PUBLISH = 'PUBLISH',
  STRATEGY_VIEW = 'STRATEGY_VIEW',
}

export interface StrategyAction {
  name: string;
  actions: ActiveAction[];
}

export interface Strategy {
  name: string;
  actions: StrategyAction[];
}

export enum ActionCategory {
  NEGOTIATION = 'NEGOTIATION',
  CONVERSATION = 'CONVERSATION',
}

export enum ActionOptionTypes {
  Range = 'range',
  Dropdown = 'dropdown',
  Percentage = 'percentage',
  DropdownPercentage = 'dropdown-percentage',
  Checkbox = 'Checkbox',
  Radio = 'Radio',
}

export enum ActionLinkTypes {
  IS = 'is',
  ON = 'on',
}

export interface ActionOption {
  label: string;
  value: string;
  type: ActionOptionTypes;
  options?: string[];
  category: ActionCategory;
  id?: string;
  showValueField?: boolean;
  placeholder?: string | string[];
  link?: ActionLinkTypes;
  isDisabled?: boolean;
}

export interface ActiveAction {
  action: ActionOption;
  value: any;
}

export enum StrategyFiledNames {
  outstandingAmount = 'dueAmount',
  delinquentDays = 'daysPastDue',
  collectionComplexity = 'aiCustomerSegment',
}

export enum StrategyFiledNameTypes {
  min_max = 'min_max',
  dropdown = 'dropdown',
  singleValue = 'singleValue',
}

export enum StrategyFiledLabels {
  outstandingAmount = 'Outstanding amount',
  delinquentDays = 'Delinquent days',
  collectionComplexity = 'Collection complexity',
}

export const fieldNameTypeMapping: Record<StrategyFiledNames, StrategyFiledNameTypes> = {
  [StrategyFiledNames.outstandingAmount]: StrategyFiledNameTypes.min_max,
  [StrategyFiledNames.delinquentDays]: StrategyFiledNameTypes.min_max,
  [StrategyFiledNames.collectionComplexity]: StrategyFiledNameTypes.dropdown,
};

export const fieldNameLabelMapping: Record<StrategyFiledNames, StrategyFiledLabels> = {
  [StrategyFiledNames.outstandingAmount]: StrategyFiledLabels.outstandingAmount,
  [StrategyFiledNames.delinquentDays]: StrategyFiledLabels.delinquentDays,
  [StrategyFiledNames.collectionComplexity]: StrategyFiledLabels.collectionComplexity,
};

export interface StrategyRule {
  fieldName: StrategyFiledNames;
  type: StrategyFiledNameTypes;
  value?: string | number | number[] | string[];
  label: StrategyFiledLabels;
  options?: string[];
  disabled?: boolean;
}

export const AllRules: StrategyRule[] = [
  {
    fieldName: StrategyFiledNames.outstandingAmount,
    type: fieldNameTypeMapping[StrategyFiledNames.outstandingAmount],
    value: [0, 0],
    label: fieldNameLabelMapping[StrategyFiledNames.outstandingAmount],
  },
  {
    fieldName: StrategyFiledNames.delinquentDays,
    type: fieldNameTypeMapping[StrategyFiledNames.delinquentDays],
    value: [0, 0],
    label: fieldNameLabelMapping[StrategyFiledNames.delinquentDays],
  },
  {
    fieldName: StrategyFiledNames.collectionComplexity,
    type: fieldNameTypeMapping[StrategyFiledNames.collectionComplexity],
    value: 'High amount, easy to collect',
    label: fieldNameLabelMapping[StrategyFiledNames.collectionComplexity],
  },
];

export interface Strategy {
  name: string;
  id: string;
  rules: StrategyRule[];
}

interface StrategyBuilderSlice {
  currentStrategyView: StrategyViews;
  currentStrategyName: string;
  strategies: any[];
  editingActionName: string;
  editingActionId: number | undefined;
  strategyList: any[];
  isEditingStrategies: boolean;
  isViewingStrategy: boolean;
  isReviewingStrategy: boolean;
  tones: string[];
  toneExamples: any;
  goBackToActions: boolean;
}

const initialState: StrategyBuilderSlice = {
  currentStrategyView: StrategyViews.LANDING,
  currentStrategyName: '',
  strategies: [],
  editingActionName: '',
  editingActionId: undefined,
  strategyList: [],
  isEditingStrategies: false,
  isViewingStrategy: false,
  isReviewingStrategy: false,
  tones: [],
  toneExamples: {},
  goBackToActions: false,
};

const StrategyBuilderSlice = createSlice({
  name: 'StrategyBuilderStateNew',
  initialState,
  reducers: {
    setCurrentStrategyView: (state, action: PayloadAction<StrategyViews>) => {
      state.currentStrategyView = action.payload;
    },
    setCurrentStrategyName: (state, action: PayloadAction<string>) => {
      state.currentStrategyName = action.payload;
    },
    setStrategies: (state, action: PayloadAction<any[]>) => {
      state.strategies = action.payload;
    },
    setStrategyList: (state, action: PayloadAction<any[]>) => {
      state.strategyList = action.payload;
    },
    setEditingActionName: (state, action: PayloadAction<string>) => {
      state.editingActionName = action.payload;
    },
    setEditingActionId: (state, action: PayloadAction<number | undefined>) => {
      state.editingActionId = action.payload;
    },
    setIsEditingStrategies: (state, action: PayloadAction<boolean>) => {
      state.isEditingStrategies = action.payload;
    },
    setIsViewingStrategy: (state, action: PayloadAction<boolean>) => {
      state.isViewingStrategy = action.payload;
    },
    setIsReviewingStrategy: (state, action: PayloadAction<boolean>) => {
      state.isReviewingStrategy = action.payload;
    },
    setConversationTonesList: (state, action: PayloadAction<string[]>) => {
      state.tones = action.payload;
    },
    setConversationToneExamples: (state, action: PayloadAction<any>) => {
      state.toneExamples = action.payload;
    },
    setGoBackToActions: (state, action: PayloadAction<boolean>) => {
      state.goBackToActions = action.payload;
    },
  },
});

export const {
  setCurrentStrategyView,
  setCurrentStrategyName,
  setStrategies,
  setEditingActionName,
  setStrategyList,
  setIsEditingStrategies,
  setEditingActionId,
  setIsViewingStrategy,
  setIsReviewingStrategy,
  setConversationTonesList,
  setConversationToneExamples,
  setGoBackToActions,
} = StrategyBuilderSlice.actions;

export default StrategyBuilderSlice.reducer;
