const OngoingCallIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='31' height='32' viewBox='0 0 31 32' fill='none'>
      <rect x='-0.000732422' y='0.499268' width='31.0015' height='31.0015' rx='15.5007' fill='#6461F3' />
      <path d='M10.9993 18.2477L10.9993 13.7523' stroke='white' strokeWidth='2' strokeLinecap='round' />
      <path d='M15.9993 18.2477L15.9993 13.7523' stroke='white' strokeWidth='2' strokeLinecap='round' />
      <path d='M20.9993 18.2477L20.9993 13.7523' stroke='white' strokeWidth='2' strokeLinecap='round' />
    </svg>
  );
};

export default OngoingCallIcon;
