import { CustomerType } from '../customer';
import { UserType } from '../user';
import { MessageSendWhatsAppTemplateRequestType } from '../websocket';
import { MESSAGE_STATUS } from './MessageType';

export type MessageWhatsAppPayloadType =
  | {
      messaging_product: 'whatsapp';
      recipient_type: 'individual';
      to: string;
      type: WHATSAPP_MESSAGE_OBJECT_TYPE.TEXT;
      text: WhatsAppMessageTextType;
    }
  | {
      messaging_product: 'whatsapp';
      recipient_type: 'individual';
      to: string;
      type: WHATSAPP_MESSAGE_OBJECT_TYPE.TEMPLATE;
      template: WhatsAppMessageTemplateType;
    };

export enum WHATSAPP_MESSAGE_OBJECT_TYPE {
  TEXT = 'text',
  TEMPLATE = 'template',
  DOCUMENT = 'document',
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  REACTION = 'reaction',
  STICKER = 'sticker',
  LOCATION = 'location',
  CONTACTS = 'contacts',
  INTERACTIVE = 'interactive',
  SYSTEM = 'system',
  BUTTON = 'button',
  ORDER = 'order',
  UNKNOWN = 'unknown',
  UNSUPPORTED = 'unsupported',
}

export type WhatsAppMessageTextType = {
  preview_url: boolean;
  body: string;
};

export type ImageParameter = {
  type: 'image';
  image: {
    link: string;
  };
};

export type TextParameter = {
  type: 'text';
  text: string;
};

export type CurrencyParameter = {
  type: 'currency';
  currency: {
    fallback_value: string;
    code: string;
    amount_1000: number;
  };
};

export type DateTimeParameter = {
  type: 'date_time';
  date_time: {
    fallback_value: string;
  };
};

export type PayloadParameter = {
  type: 'payload';
  payload: string;
};

export type HeaderComponent = {
  type: 'header';
  parameters: ImageParameter[];
};

export type BodyComponent = {
  type: 'body';
  parameters: (TextParameter | CurrencyParameter | DateTimeParameter)[];
};

export type ButtonComponent = {
  type: 'button';
  sub_type: 'quick_reply';
  index: '0' | '1';
  parameters: PayloadParameter[];
};

export type WhatsAppMessageTemplateType = {
  name: string;
  language: {
    code: string;
  };
  components: (HeaderComponent | BodyComponent | ButtonComponent)[];
};

export type WhatsAppContactObjectType = {
  input: string;
  wa_id: string;
  profile: {
    name: string;
  };
};

export type WhatsAppError = {
  code: string;
  title: string;
  message: string;
  error_data: {
    details: string;
  };
};

export type WhatsAppMessage = {
  id: string;
  message_status: 'accepted' | 'held_for_quality_assessment';
};

export type WhatsAppResultPayload = {
  messaging_product: 'whatsapp';
  contacts: WhatsAppContactObjectType[];
  messages: WhatsAppMessage[];
};

export type WhatsAppMessageTextObjectType = {
  from: string;
  id: string;
  timestamp: string;
  type: WHATSAPP_MESSAGE_OBJECT_TYPE.TEXT;
  [WHATSAPP_MESSAGE_OBJECT_TYPE.TEXT]: {
    body: string;
  };
};

export type WhatsAppMessageDocumentObjectType = {
  from: string;
  id: string;
  timestamp: string;
  type: WHATSAPP_MESSAGE_OBJECT_TYPE.DOCUMENT;
  [WHATSAPP_MESSAGE_OBJECT_TYPE.DOCUMENT]: {
    filename: string;
    mime_type: string;
    sha256: string;
    id: string;
  };
};

export type WhatsAppMessageImageObjectType = {
  from: string;
  id: string;
  timestamp: string;
  type: WHATSAPP_MESSAGE_OBJECT_TYPE.IMAGE;
  [WHATSAPP_MESSAGE_OBJECT_TYPE.IMAGE]: {
    caption: string;
    mime_type: string;
    sha256: string;
    id: string;
  };
};

export type WhatsAppMessageVideoObjectType = {
  from: string;
  id: string;
  timestamp: string;
  type: WHATSAPP_MESSAGE_OBJECT_TYPE.VIDEO;
  [WHATSAPP_MESSAGE_OBJECT_TYPE.VIDEO]: {
    mime_type: string;
    sha256: string;
    id: string;
  };
};

export type WhatsAppMessageAudioObjectType = {
  from: string;
  id: string;
  timestamp: string;
  type: WHATSAPP_MESSAGE_OBJECT_TYPE.AUDIO;
  [WHATSAPP_MESSAGE_OBJECT_TYPE.AUDIO]: {
    mime_type: string;
    sha256: string;
    id: string;
    voice: boolean;
  };
};

export type WhatsAppMessageReactionObjectType = {
  from: string;
  id: string;
  timestamp: string;
  type: WHATSAPP_MESSAGE_OBJECT_TYPE.REACTION;
  [WHATSAPP_MESSAGE_OBJECT_TYPE.REACTION]: {
    message_id: string;
    emoji: string;
  };
};

export type WhatsAppMessageStickerObjectType = {
  from: string;
  id: string;
  timestamp: string;
  type: WHATSAPP_MESSAGE_OBJECT_TYPE.STICKER;
  [WHATSAPP_MESSAGE_OBJECT_TYPE.STICKER]: {
    mime_type: string;
    sha256: string;
    id: string;
    animated: boolean;
  };
};

export type WhatsAppMessageUnknownObjectType = {
  from: string;
  id: string;
  timestamp: string;
  type: WHATSAPP_MESSAGE_OBJECT_TYPE.UNKNOWN;
  errors: {
    code: string;
    details: string;
    title: string;
  };
};

export type WhatsAppMessageUnsupportedObjectType = {
  from: string;
  id: string;
  timestamp: string;
  type: WHATSAPP_MESSAGE_OBJECT_TYPE.UNSUPPORTED;
  errors: {
    code: string;
    title: string;
    message: string;
    error_data: {
      details: string;
    };
  };
};

export type WhatsAppMessageStatusObjectType = {
  id: string;
  status: MESSAGE_STATUS;
  timestamp: string;
  recipient_id: string;
  conversation: {
    id: string;
    origin: {
      type: string;
    };
  };
  pricing: {
    billable: boolean;
    pricing_model: string;
    category: string;
  };
};

export type WhatsAppMetadataObjectType = {
  display_phone_number: string;
  phone_number_id: string;
};

export type WhatsAppErrorsObjectType = {
  code: string;
  title: string;
  message: string;
  error_data: {
    details: string;
  };
};

export type WhatsAppReceiveMessageChangesObject = {
  value: {
    messaging_product: 'whatsapp';
    messages: Array<WhatsAppMessageBodyObjectType>;
    statuses: Array<WhatsAppMessageStatusObjectType>;
    contacts: Array<WhatsAppContactObjectType>;
    metadata: WhatsAppMetadataObjectType;
    errors: Array<WhatsAppErrorsObjectType>;
  };
  field: 'messages';
};

export type WhatsAppMessageBodyObjectType =
  | WhatsAppMessageTextObjectType
  | WhatsAppMessageDocumentObjectType
  | WhatsAppMessageImageObjectType
  | WhatsAppMessageVideoObjectType
  | WhatsAppMessageAudioObjectType
  | WhatsAppMessageStickerObjectType
  | WhatsAppMessageReactionObjectType
  | WhatsAppMessageUnknownObjectType
  | WhatsAppMessageUnsupportedObjectType;

export type WhatsAppReceiveWebhookType = {
  object: 'whatsapp_business_account';
  entry: Array<{
    id: string;
    changes: Array<WhatsAppReceiveMessageChangesObject>;
  }>;
};

export type WhatsAppMediaUrlDownloadType = {
  messaging_product: 'whatsapp';
  url: string;
  mime_type: string;
  sha256: string;
  file_size: number;
  id: string;
};

export type WhatsAppMessageSendBodyType = {
  user: UserType;
  payload: MessageSendWhatsAppTemplateRequestType;
  customer: CustomerType;
};
