import { GenericObject } from '@finance-ops/types';
import { LocalStorageKeys } from '../../constants/localStorageKeys';
import { safeLocalStorage } from 'apps/webapp/src/hooks/useLocalStorage';

const useLocalStorageDashboardState = () => {
  const setLocalStorageDashboardState = (value: GenericObject) => {
    try {
      const previousValue = safeLocalStorage.getItem(LocalStorageKeys.DASHBOARD_STATE);
      if (previousValue) {
        safeLocalStorage.setItem(
          LocalStorageKeys.DASHBOARD_STATE,
          JSON.stringify({ ...JSON.parse(previousValue), ...value }),
        );
      }
    } catch (error) {
      safeLocalStorage.setItem(LocalStorageKeys.DASHBOARD_STATE, JSON.stringify({}));
      console.error(error);
    }
  };
  const getLocalStorageDashboardState = () => {
    if (safeLocalStorage.getItem(LocalStorageKeys.DASHBOARD_STATE)) {
      return JSON.parse(safeLocalStorage.getItem(LocalStorageKeys.DASHBOARD_STATE) as string);
    }
    safeLocalStorage.setItem(LocalStorageKeys.DASHBOARD_STATE, '{}');
    return {};
  };

  const resetLocastorageDashboardState = () => {
    safeLocalStorage.removeItem(LocalStorageKeys.DASHBOARD_STATE);
  };

  return {
    setLocalStorageDashboardState,
    getLocalStorageDashboardState,
    resetLocastorageDashboardState,
  };
};
export default useLocalStorageDashboardState;
