import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { formatDateAndTime } from '../../@core/utils/format';
import { format } from 'timeago.js';
import { TIME_FORMAT } from '@finance-ops/types';

type DateAgoProps = { date: Date; sx?: SxProps<Theme>; timeFormat: TIME_FORMAT | undefined };

function DateAgo({ date, sx, timeFormat }: DateAgoProps) {
  return (
    <Tooltip title={formatDateAndTime(date, timeFormat)}>
      <Typography component={'span'} sx={sx}>
        {format(date)}{' '}
      </Typography>
    </Tooltip>
  );
}
export default DateAgo;
