import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Bounce, ToastPosition, TypeOptions, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../public/images/logos/logo.png';
// ** MUI Imports
import AddAlertIcon from '@mui/icons-material/AddAlert';
import ClearIcon from '@mui/icons-material/Clear';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MuiMenu, { MenuProps } from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

// ** Third Party Components
import { EVENT_TYPE, NOTIFICATION_INDICATOR_RESOLUTION_CENTER_EVENTS } from '@finance-ops/constants';
import {
  ClientType,
  NOTIFICATION_STATUS,
  NotificationType,
  PERMISSION_ACTION,
  PERMISSION_SUBJECT,
} from '@finance-ops/types';
import { Button, Card, Grid, useTheme } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import PerfectScrollbarComponent from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import Badge from '../@core/components/mui/badge';
import { useAuth } from '../hooks/useAuth';
import { useNotifications } from '../hooks/useNotifications';
import { AbilityContext } from '../layouts/components/acl/Can';
import { getNotificationCategoriesTypes, notificationCategories } from '../pages/notification';
import { RootState } from '../store';
import { clientSelectors } from '../store/client';
import { selectNotificationsByUserId } from '../store/notification';
import { setupPushNotifications } from '../utils/service-worker/service-worker-utils';
import { safeLocalStorage } from 'apps/webapp/src/hooks/useLocalStorage';
// ** Styled Menu component
const Menu = styled(MuiMenu)<MenuProps>(({ theme }) => ({
  '& .MuiMenu-paper': {
    width: 528,
    // overflow: 'hidden',
    marginTop: theme.spacing(-4),
    height: '100vh',
    minHeight: '100vh',
    maxHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  '& .MuiMenu-list': {
    padding: 0,
    paddingBottom: '50px',
    height: '100vh',
    minHeight: '100vh',
    maxHeight: '100vh',
    '& .MuiMenuItem-root': {
      margin: 0,
      borderRadius: 0,
      padding: theme.spacing(4, 6),
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
}));

// ** Styled PerfectScrollbar component
const PerfectScrollbar = styled(PerfectScrollbarComponent)({
  maxHeight: '95%',
  height: '95%',
  minHeight: '95%',
});

const ScrollWrapper = ({ children, hidden }: { children: ReactNode; hidden: boolean }) => {
  if (hidden) {
    return <Box sx={{ maxHeight: 349, overflowY: 'auto', overflowX: 'hidden' }}>{children}</Box>;
  } else {
    return <PerfectScrollbar options={{ wheelPropagation: false, suppressScrollX: true }}>{children}</PerfectScrollbar>;
  }
};

interface ToastProps {
  msg: { content: string };
  position?: ToastPosition;
  timeout?: number;
  type?: TypeOptions;
  playAudio?: boolean;
  id?: string;
}

let audio: HTMLAudioElement | null = null;
if (typeof window !== 'undefined') {
  audio = new Audio('/audio/sound.mp3');
}

// Set to keep track of currently displayed notifications
const activeNotifications = new Set<string>();

export const notify = ({
  msg,
  position = 'top-right',
  timeout = 5000,
  type = 'success',
  playAudio = true,
  id,
}: ToastProps) => {
  // Check if the notification is already displayed

  if (safeLocalStorage.getItem('hide-notifications') === 'true') {
    return;
  }
  if (id && activeNotifications.has(id)) {
    return;
  } else if (id) {
    activeNotifications.add(id);
  }
  msg.content =
    msg.content
      ?.toString()
      ?.replace(/<(.|\n)*?>/g, '')
      .trim() ?? '';

  const content =
    type === 'info' ? (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Image src={logo} alt='Logo' width={20} />
        <span style={{ paddingLeft: '10px' }}>{msg.content}</span>
      </div>
    ) : (
      msg.content
    );

  toast(content, {
    position,
    type: type === 'info' ? undefined : type,
    autoClose: timeout,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Bounce,
    style: {
      borderRadius: '30px',
      paddingRight: '10px',
    },
  });

  if (playAudio) {
    if (safeLocalStorage.getItem('mute-notification-sound') === 'true') {
      return;
    }
    (audio as HTMLAudioElement).play();
  }
};

const StyledCard = styled(Card)(({ theme }) => ({
  width: 420,
  minHeight: 80,
  background: '#F9F9F9',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.07)',
  borderRadius: 6,
  margin: '15px 50px',
  padding: '12px 50px',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: 100,

  ':hover': {
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
  },
  '& .MuiCardContent-root': {
    padding: '0',
    minHeight: '60px',
    // background: 'red'
  },
}));

export function NotificationCard({
  notification,
  onClickHandler,
  getNotificationCategoriesForUser,
}: Readonly<{
  notification: NotificationType;
  onClickHandler: (notification: NotificationType) => void;
  getNotificationCategoriesForUser: any;
}>) {
  const clients: ClientType[] = useSelector(clientSelectors.selectAll);
  const clientName = clients.find(client => client.id === notification.data?.tasks?.clientId)?.name ?? '';
  return (
    <StyledCard
      sx={{ backgroundColor: notification.status === NOTIFICATION_STATUS.UNREAD ? '#F0F0FF' : '#EFEFEF' }}
      onClick={() => {
        onClickHandler(notification);
      }}
    >
      <span>
        {getNotificationCategoriesTypes(getNotificationCategoriesForUser()).types[notification.event]?.GetTemplate(
          notification,
          clientName,
        )}
      </span>
    </StyledCard>
  );
}

export type NotificationPropsType = {
  navHover?: boolean;
};

const Notifications = ({ navHover }: NotificationPropsType) => {
  // ** States
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);
  const router = useRouter();
  const { user } = useAuth();
  const ability = useContext(AbilityContext);
  const theme = useTheme();
  const { allNotifications, allReadNotifications, getPreviousNotifications, getNotifications } = useNotifications();

  // load notifications on when loading the page
  useEffect(() => {
    if (user?.id) {
      getNotifications(user.id);
    }
  }, [user?.id]);

  // // Load previous notifications when notification is read
  // useEffect(() => {
  //   if (user?.id && allReadNotifications.length > 0) {
  //     getPreviousNotifications(user.id, allNotifications[allNotifications.length - 1]?.createdAt);
  //   }
  // }, [allReadNotifications.length]);

  const latestTimestampRef = useRef<Date>();

  // Update ref whenever allNotifications changes
  useEffect(() => {
    if (allNotifications.length > 0) {
      latestTimestampRef.current = allNotifications[allNotifications.length - 1].createdAt;
    }
  }, [allNotifications.length]);

  // Refetch previous notifications every 2 minutes
  useEffect(() => {
    if (!user?.id) return;

    const interval = setInterval(() => {
      if (latestTimestampRef.current) {
        getPreviousNotifications(user.id, latestTimestampRef.current);
      }
    }, 2 * 60 * 1000);

    return () => clearInterval(interval);
  }, [user?.id]);

  // ** Hook
  const hidden = false;

  const notifications: NotificationType[] = useSelector((state: RootState) =>
    selectNotificationsByUserId(state, user?.id),
  );
  const getNotificationCategoriesForUser = () => {
    if (ability?.can(PERMISSION_ACTION.READ, PERMISSION_SUBJECT.ONLY_SUPER_ADMIN)) {
      return notificationCategories;
    }

    // TODO: Filter notifications to show as per requirement
    return notificationCategories;
  };

  const notificationsToShow = notifications
    .filter(notification => {
      return Object.keys(getNotificationCategoriesTypes(getNotificationCategoriesForUser()).types).includes(
        notification.event,
      );
    })
    .filter(notification => {
      if (safeLocalStorage.getItem('notificationsClearedAt')) {
        return (
          new Date(notification.createdAt) > new Date(safeLocalStorage.getItem('notificationsClearedAt') as string)
        );
      } else {
        return true;
      }
    })
    .sort((a, b) => {
      // Prioritize unread over read
      if (a.status === NOTIFICATION_STATUS.UNREAD && b.status === NOTIFICATION_STATUS.READ) return -1;
      if (a.status === NOTIFICATION_STATUS.READ && b.status === NOTIFICATION_STATUS.UNREAD) return 1;

      // If both have the same status, compare by createdAt
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB.getTime() - dateA.getTime();
    });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDropdownClose = () => {
    safeLocalStorage.removeItem('notifications');
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCardClickHandler = (notification: NotificationType) => {
    handleClose();
    if (NOTIFICATION_INDICATOR_RESOLUTION_CENTER_EVENTS.includes(notification.event)) {
      router.push('/resolution-center/?id=' + notification.data?.actions?.id);
      return;
    }
    if (
      notification.event === EVENT_TYPE.MESSAGE_INBOUND ||
      notification.event === EVENT_TYPE.TASK_UPDATE_MODE ||
      notification.event === EVENT_TYPE.PAYMENT_SUCCESS ||
      notification.event === EVENT_TYPE.PAYMENT_LINK_OPENED ||
      notification.event === EVENT_TYPE.INVOICE_LINK_OPENED ||
      notification.event === EVENT_TYPE.NOTE_CREATED ||
      notification.event === EVENT_TYPE.EMAIL_RECEIVED
    ) {
      router.push(`/chat-beta/?clientId=${notification.data?.tasks?.clientId}&taskId=${notification.data?.tasks?.id}`);
      return;
    }
  };

  const titleStyles = {
    box: { display: 'flex', alignItems: 'center' },
    typography: { ml: 2, fontSize: '0.8rem', color: theme.palette.secondary.dark, fontWeight: 600 },
  };

  return (
    <>
      <Button
        onClick={handleClick}
        variant='text'
        sx={{
          m: 0,
          p: 2,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: theme.palette.grey[100],
          },
        }}
      >
        <Badge
          color='primary'
          variant='dot'
          invisible={
            notificationsToShow.filter(notification => notification.status === NOTIFICATION_STATUS.UNREAD).length === 0
          }
          showZero={false} // This ensures the badge is hidden when there are no unread notifications
        >
          <NotificationsActiveOutlinedIcon sx={{ color: theme.palette.secondary.dark }} />
          {navHover && <Typography sx={titleStyles.typography}>Notifications</Typography>}
        </Badge>
      </Button>

      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={{ top: 0, left: 260 }}
        style={{
          maxHeight: '100vh',
          minHeight: '100vh',
          height: '100vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 64,
            width: '100%',
          }}
        >
          <Typography
            sx={{ fontWeight: 700, fontSize: '14px', lineHeight: '30px', display: 'flex', textAlign: 'center' }}
          >
            Notifications
          </Typography>
          <IconButton
            onClick={() => {
              setupPushNotifications();
            }}
          >
            <AddAlertIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              handleClose();
              router.push('/notification');
            }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'fit-content',
              position: 'absolute',
              right: '100px',
            }}
          >
            <OpenInNewRoundedIcon sx={{ fontSize: '0.8em' }}></OpenInNewRoundedIcon>
          </IconButton>

          <IconButton
            onClick={handleDropdownClose}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'fit-content',
              position: 'absolute',
              right: '50px',
            }}
          >
            <ClearIcon sx={{ fontSize: '0.8em' }}></ClearIcon>
          </IconButton>
        </Box>
        <Grid
          container
          justifyContent='space-between'
          justifySelf='center'
          alignContent='center'
          sx={{ padding: '0px 50px' }}
        >
          <Grid item></Grid>
          {/*

          Disabled clear button since it only hides notifications which was also hiding lazy loaded older notifications

          <Grid item>
            {notificationsToShow.length > 0 && (
              <Button
                variant='contained'
                sx={{ background: '#CECDFF', borderRadius: '17px', width: '56px', height: '31px' }}
                onClick={() => {
                  safeLocalStorage.setItem('notificationsClearedAt', new Date().toISOString());
                  setNotificationsCleared(true);
                }}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '30px',
                    color: '#6461F3',
                  }}
                >
                  Clear
                </Typography>
              </Button>
            )}
          </Grid> */}
        </Grid>
        {notificationsToShow.length > 0 ? (
          <ScrollWrapper hidden={hidden}>
            {notificationsToShow.map((notification, index: number) => (
              <NotificationCard
                key={'notificationSidebar' + notification.id}
                notification={notification}
                onClickHandler={onCardClickHandler}
                getNotificationCategoriesForUser={getNotificationCategoriesForUser}
              ></NotificationCard>
            ))}
          </ScrollWrapper>
        ) : (
          <Typography sx={{ textAlign: 'center' }}> No New Notifications</Typography>
        )}
      </Menu>
    </>
  );
};

export default Notifications;
