import { Box, Typography } from '@mui/material';

interface CustomCellProps {
  text: string;
  isError: boolean;
}

export default function CustomCell({ text, isError }: CustomCellProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography
        sx={{
          px: 2,
          py: 0.5,
          borderRadius: 1,
          width: 'fit-content',
          backgroundColor: isError ? '#d93025' : 'inherit',
          color: isError ? '#ffffff' : '#303942',
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}
