interface IpApiResponse {
  ip: string;
  city: string;
  region: string;
  region_code: string;
  country_code: string;
  country_code_iso3: string;
  country_name: string;
  country_capital: string;
  country_tld: string;
  continent_code: string;
  in_eu: boolean;
  postal: string;
  latitude: number;
  longitude: number;
  timezone: string;
  utc_offset: string;
  country_calling_code: string;
  currency: string;
  currency_name: string;
  languages: string;
  asn: string;
  org: string;
}

interface DefaultLocationData {
  ip: string;
  city: string;
  region: string;
  country_name: string;
}

type IpApiFields = keyof IpApiResponse;

async function fetchIpData<T extends IpApiFields[]>(
  fields?: T,
): Promise<T extends IpApiFields[] ? Pick<IpApiResponse, T[number]> : DefaultLocationData> {
  try {
    const baseUrl = 'https://ipapi.co/json';
    const response = await fetch(baseUrl);

    if (!response.ok) {
      throw new Error(`IpApi request failed: ${response.statusText}`);
    }

    const data: IpApiResponse = await response.json();

    if (fields?.length) {
      const filteredData = fields.reduce(
        (acc, field) => ({
          ...acc,
          [field]: data[field],
        }),
        {},
      );
      return filteredData as T extends IpApiFields[] ? Pick<IpApiResponse, T[number]> : DefaultLocationData;
    }

    return {
      ip: data.ip,
      city: data.city,
      region: data.region,
      country_name: data.country_name,
    } as any;
  } catch (error) {
    console.dir('Error fetching IP data:', error);
    throw error;
  }
}

export { fetchIpData };
export type { DefaultLocationData, IpApiResponse };
