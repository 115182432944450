const CallTranscriptSmallIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='29' height='30' viewBox='0 0 29 30' fill='none'>
      <rect x='0.598999' y='1.19421' width='27.6115' height='27.6115' rx='13.8058' stroke='#CECECE' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2068 13.8006V17.3988C10.2068 18.7237 11.2808 19.7977 12.6056 19.7977H16.2039C17.5287 19.7977 18.6027 18.7237 18.6027 17.3988V13.8006C18.6027 12.4757 17.5287 11.4017 16.2039 11.4017H12.6056C11.2808 11.4017 10.2068 12.4757 10.2068 13.8006Z'
        stroke='#6461F3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.1041 12.0014C15.1041 12.2775 15.3279 12.5014 15.6041 12.5014C15.8802 12.5014 16.1041 12.2775 16.1041 12.0014H15.1041ZM16.1041 10.2023C16.1041 9.92613 15.8802 9.70227 15.6041 9.70227C15.3279 9.70227 15.1041 9.92613 15.1041 10.2023H16.1041ZM12.7052 12.0014C12.7052 12.2775 12.929 12.5014 13.2052 12.5014C13.4813 12.5014 13.7052 12.2775 13.7052 12.0014H12.7052ZM13.7052 10.2023C13.7052 9.92613 13.4813 9.70227 13.2052 9.70227C12.929 9.70227 12.7052 9.92613 12.7052 10.2023H13.7052ZM16.2038 14.3006C16.4799 14.3006 16.7038 14.0767 16.7038 13.8006C16.7038 13.5244 16.4799 13.3006 16.2038 13.3006V14.3006ZM12.6055 13.3006C12.3294 13.3006 12.1055 13.5244 12.1055 13.8006C12.1055 14.0767 12.3294 14.3006 12.6055 14.3006V13.3006ZM16.2038 16.0997C16.4799 16.0997 16.7038 15.8758 16.7038 15.5997C16.7038 15.3235 16.4799 15.0997 16.2038 15.0997V16.0997ZM12.6055 15.0997C12.3294 15.0997 12.1055 15.3235 12.1055 15.5997C12.1055 15.8758 12.3294 16.0997 12.6055 16.0997V15.0997ZM14.4047 17.8988C14.6808 17.8988 14.9047 17.675 14.9047 17.3988C14.9047 17.1227 14.6808 16.8988 14.4047 16.8988V17.8988ZM12.6055 16.8988C12.3294 16.8988 12.1055 17.1227 12.1055 17.3988C12.1055 17.675 12.3294 17.8988 12.6055 17.8988V16.8988ZM16.1041 12.0014V10.2023H15.1041V12.0014H16.1041ZM13.7052 12.0014V10.2023H12.7052V12.0014H13.7052ZM16.2038 13.3006H12.6055V14.3006H16.2038V13.3006ZM16.2038 15.0997H12.6055V16.0997H16.2038V15.0997ZM14.4047 16.8988H12.6055V17.8988H14.4047V16.8988Z'
        fill='#6461F3'
      />
    </svg>
  );
};

export default CallTranscriptSmallIcon;
