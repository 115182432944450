import { JsonEditor } from 'json-edit-react';
import type { Log } from '../../utils/pglite/types';

export type JsonBodyProps = {
  log: Log;
};

export default function JsonBody({ log }: JsonBodyProps) {
  return (
    <JsonEditor
      theme='githubDark'
      minWidth='100%'
      showErrorMessages
      rootName=''
      rootFontSize={12}
      restrictAdd={true}
      restrictDelete={true}
      restrictEdit={true}
      jsonParse={(input: string | any) => {
        try {
          // If input is already an object, return it
          if (typeof input === 'object' && input !== null) {
            return input;
          }
          // Handle string input
          if (typeof input === 'string') {
            // Try to parse the string as JSON
            return JSON.parse(input);
          }
          return input;
        } catch (error) {
          console.error('[DevTools] JSON parse error:', error);
          // If parsing fails, return the original input
          return input;
        }
      }}
      data={(() => {
        const data = log.response_body || log.websocket_payload;
        try {
          return typeof data === 'string' ? JSON.parse(data) : data;
        } catch (error) {
          console.error('[DevTools] Initial parse error:', error);
          return data;
        }
      })()}
    />
  );
}
