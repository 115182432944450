import { USER_STATUS, UserDocumentType } from '@finance-ops/types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { httpGet } from '../../httpClient';
import { ROLE_API_PATHS, USERS_API_PATHS } from '../../httpClient/api';
import { RootState } from '../../store';
import { useCreateUserMutation, useDeleteUserMutation, useUpdateUserMutation } from '../../store/api/usersApi';
import { upsertManyRoles } from '../../store/roles';
import { deleteUser as deleteUserAction, setUsers, upsertUser } from '../../store/users';
import { useAuth } from '../useAuth';

export const useGetUsers = () => {
  const selectedClientId = useSelector((state: RootState) => state.client.selectedClientId);
  const dispatch = useDispatch();
  const user = useAuth().user;
  const getUsers = useCallback(async () => {
    try {
      let path = `${USERS_API_PATHS.GET_USERS}`;
      // if (selectedClientId) {
      //   path += `?customerId=${selectedClientId}`;
      // }
      path += `?isInternal=${user?.role?.isInternal}&isSuperAdmin=${Boolean(user?.role?.isInternal)}`;
      const response = await httpGet(path);
      if (response?.data?.length > 0) {
        dispatch(setUsers(response.data));
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  }, [selectedClientId]);
  return { getUsers };
};

export const useGetRoles = () => {
  const dispatch = useDispatch();
  const isInternalUser = useAuth().user?.role?.isInternal;
  const isSuperAdmin = useAuth().user?.role?.isInternal;
  const selectedClientId = useSelector((state: RootState) => state.client.selectedClientId);

  const getRoles = useCallback(async () => {
    try {
      //TODO: RAJAT let path = `${ROLE_API_PATHS.GET_ROLES}?isInternal=${isInternalUser}&isSuperAdmin=${isSuperAdmin}`;
      let path = `${ROLE_API_PATHS.GET_ROLES}`;
      if (selectedClientId) {
        path += `&clientId=${selectedClientId}`;
      }
      const response = await httpGet(path);
      dispatch(upsertManyRoles(response.data));
      return response.data;
    } catch (error) {
      console.error(error);
      const errorMessage = error instanceof Error ? error.message : 'Failed to fetch roles';
      return { data: null, error: errorMessage };
    }
  }, [isInternalUser, isSuperAdmin, selectedClientId]);
  return { getRoles };
};

export const useUsers = () => {
  const dispatch = useDispatch();
  const isInternalUser = useAuth().user?.role?.isInternal;
  const isSuperAdmin = useAuth().user?.role?.isInternal;
  const selectedClientId = useSelector((state: RootState) => state.client.selectedClientId);

  const [updateUser] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [createUser] = useCreateUserMutation();

  const createOneUser = useCallback(
    async (user: Partial<UserDocumentType>) => {
      try {
        const createdUser = await createUser(user).unwrap();
        dispatch(upsertUser(createdUser));
        return { data: createdUser, error: null };
      } catch (error: any) {
        console.error(error);
        const errorMessage = error.data?.error ? error.data.error : 'Failed to create user';
        return { data: null, error: errorMessage };
      }
    },
    [createUser, dispatch],
  );

  const updateOneUser = useCallback(
    async (userId: string, { firstName, lastName, contactNumber, email, roleId, type }: Partial<UserDocumentType>) => {
      try {
        const updatedUser = await updateUser({
          user: { firstName, lastName, contactNumber, email, roleId, type },
          id: userId,
        }).unwrap();
        dispatch(upsertUser(updatedUser));
        return { data: updatedUser, error: null };
      } catch (error: any) {
        console.error(error);
        const errorMessage = error.data?.error ? error.data.error : 'Failed to create user';
        return { data: null, error: errorMessage };
      }
    },
    [dispatch, updateUser],
  );

  const deleteOneUser = useCallback(
    async (userId: string) => {
      try {
        const deletedUser = await deleteUser({ id: userId }).unwrap();
        dispatch(deleteUserAction(deletedUser.id));
        return { data: deletedUser, error: null };
      } catch (error: any) {
        console.error(error);
        const errorMessage = error.data?.error ? error.data.error : 'Failed to create user';
        return { data: null, error: errorMessage };
      }
    },
    [deleteUser, dispatch],
  );

  const disableOneUser = useCallback(
    async (userId: string) => {
      try {
        const updateUserDto = {
          status: USER_STATUS.DISABLED,
        };
        const disabledUser = await updateUser({ id: userId, user: updateUserDto }).unwrap();
        dispatch(upsertUser(disabledUser));
        return { data: disabledUser, error: null };
      } catch (error: any) {
        console.error(error);
        const errorMessage = error.data?.error ? error.data.error : 'Failed to create user';
        return { data: null, error: errorMessage };
      }
    },
    [updateUser, dispatch],
  );

  const enableOneUser = useCallback(
    async (userId: string) => {
      try {
        const updateUserDto = {
          status: USER_STATUS.ACTIVE,
        };
        const enabledUser = await updateUser({ id: userId, user: updateUserDto }).unwrap();
        dispatch(upsertUser(enabledUser));
        return { data: enabledUser, error: null };
      } catch (error: any) {
        console.error(error);
        const errorMessage = error.data?.error ? error.data.error : 'Failed to create user';
        return { data: null, error: errorMessage };
      }
    },
    [updateUser, dispatch],
  );

  return { createOneUser, updateOneUser, deleteOneUser, disableOneUser, enableOneUser };
};
