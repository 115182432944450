class LocalStorageWrapper {
  private isClient: boolean;
  private store: Map<string, string>;

  constructor() {
    this.isClient = typeof window !== 'undefined';
    this.store = new Map();
  }

  getItem(key: string): string | null {
    if (this.isClient) {
      return window.localStorage.getItem(key);
    }
    return this.store.get(key) || null;
  }

  setItem(key: string, value: string): void {
    if (this.isClient) {
      window.localStorage.setItem(key, value);
    } else {
      this.store.set(key, value);
    }
  }

  removeItem(key: string): void {
    if (this.isClient) {
      window.localStorage.removeItem(key);
    } else {
      this.store.delete(key);
    }
  }

  clear(): void {
    if (this.isClient) {
      window.localStorage.clear();
    } else {
      this.store.clear();
    }
  }

  key(index: number): string | null {
    if (this.isClient) {
      return window.localStorage.key(index);
    }
    return Array.from(this.store.keys())[index] || null;
  }

  get length(): number {
    if (this.isClient) {
      return window.localStorage.length;
    }
    return this.store.size;
  }
}

export const safeLocalStorage = new LocalStorageWrapper();
