const MuteMicIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='41' height='42' viewBox='0 0 41 42' fill='none'>
      <rect y='0.759277' width='40.5' height='40.5' rx='20.25' fill='#6461F3' />
      <path
        d='M20.25 12.3258C19.5677 12.3258 18.9133 12.5969 18.4309 13.0793C17.9484 13.5618 17.6774 14.2161 17.6774 14.8984V20.9012C17.6774 21.5835 17.9484 22.2379 18.4309 22.7204C18.9133 23.2028 19.5677 23.4739 20.25 23.4739C20.9323 23.4739 21.5867 23.2028 22.0691 22.7204C22.5516 22.2379 22.8226 21.5835 22.8226 20.9012V14.8984C22.8226 14.2161 22.5516 13.5618 22.0691 13.0793C21.5867 12.5969 20.9323 12.3258 20.25 12.3258Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.2528 19.1862V20.9012C26.2528 22.4933 25.6204 24.0201 24.4946 25.1459C23.3689 26.2716 21.842 26.9041 20.25 26.9041M20.25 26.9041C18.658 26.9041 17.1311 26.2716 16.0054 25.1459C14.8796 24.0201 14.2472 22.4933 14.2472 20.9012V19.1862M20.25 26.9041V29.4767'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M11.6398 31.0686L28.8602 10.9498' stroke='white' strokeWidth='1.5' strokeLinecap='round' />
    </svg>
  );
};

export default MuteMicIcon;
