import { Box, Typography } from '@mui/material';

const HttpMethodText = ({ method }: { method: string }) => {
  let methodColor = '#';
  if (method === 'GET') {
    methodColor = '#00AA00';
  } else if (method === 'POST') {
    methodColor = '#ffa500';
  } else if (method === 'PUT') {
    methodColor = '#0000ff';
  } else if (method === 'DELETE') {
    methodColor = '#ff0000';
  } else if (method === 'PATCH') {
    methodColor = '#ff0080';
  } else {
    methodColor = '#000000';
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: 0.5,
        borderRadius: 1,
        width: 'fit-content',
      }}
    >
      <Typography sx={{ color: methodColor, fontWeight: 900 }}>{method}</Typography>
    </Box>
  );
};

export default HttpMethodText;
