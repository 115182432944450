const CallRingingIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'>
      <rect x='1.21057' y='1.19421' width='27.6115' height='27.6115' rx='13.8058' stroke='#CECECE' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.348 12.1368C10.9109 11.5433 11.1102 11.3769 11.3498 11.3C11.5159 11.2563 11.6901 11.2537 11.8574 11.2925C12.0776 11.3567 12.1351 11.4052 12.8577 12.1241C13.4922 12.7556 13.5549 12.8243 13.6161 12.9452C13.7345 13.1628 13.7535 13.4209 13.6683 13.6536C13.6056 13.829 13.5131 13.9455 13.1286 14.3321L12.8778 14.5837C12.8118 14.6506 12.7962 14.7523 12.839 14.836C13.3958 15.7859 14.1855 16.5783 15.1337 17.1381C15.243 17.1967 15.3778 17.1777 15.4666 17.0911L15.7077 16.8537C15.8567 16.7008 16.0144 16.5568 16.1802 16.4222C16.4401 16.2614 16.7646 16.2459 17.0387 16.3812C17.1723 16.4439 17.2171 16.4849 17.8717 17.1374C18.5435 17.8092 18.5652 17.8308 18.6406 17.9854C18.7813 18.2413 18.7799 18.5518 18.6368 18.8065C18.5622 18.9498 18.5204 19.002 18.1412 19.3895C17.912 19.6239 17.6933 19.8373 17.662 19.8687C17.3488 20.1275 16.9473 20.2547 16.5422 20.2233C15.8012 20.1554 15.0839 19.9261 14.4409 19.5514C13.0158 18.7964 11.8027 17.6962 10.9124 16.3513C10.7177 16.0702 10.5445 15.7747 10.3943 15.4675C9.99415 14.7815 9.78727 13.9999 9.79566 13.2057C9.82576 12.7888 10.0254 12.4025 10.348 12.1368Z'
        stroke='#6461F3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.7485 9.30134C16.4886 9.20806 16.2022 9.34313 16.109 9.60304C16.0157 9.86295 16.1508 10.1493 16.4107 10.2426L16.7485 9.30134ZM19.7696 13.5453C19.8672 13.8036 20.1558 13.9338 20.4141 13.8361C20.6724 13.7385 20.8026 13.4499 20.705 13.1916L19.7696 13.5453ZM15.8686 10.966C15.5993 10.9052 15.3316 11.0742 15.2708 11.3436C15.2099 11.613 15.379 11.8806 15.6483 11.9415L15.8686 10.966ZM18.1167 14.369C18.182 14.6373 18.4525 14.8019 18.7208 14.7366C18.9891 14.6713 19.1536 14.4008 19.0883 14.1325L18.1167 14.369ZM16.4107 10.2426C17.9598 10.7986 19.1875 12.0057 19.7696 13.5453L20.705 13.1916C20.0193 11.3781 18.5733 9.95629 16.7485 9.30134L16.4107 10.2426ZM15.6483 11.9415C16.8653 12.2163 17.8216 13.1568 18.1167 14.369L19.0883 14.1325C18.7034 12.5513 17.456 11.3245 15.8686 10.966L15.6483 11.9415Z'
        fill='#6461F3'
      />
    </svg>
  );
};

export default CallRingingIcon;
