import { LANGUAGE } from '../common';
import { AttachmentType } from '../common/AttachmentType';
import { AssignedStrategyData } from '../common/Strategy';
import { Prediction } from '../predictions';
import { MESSAGE_DELIVERY_TYPE } from '../sqs';

export type MessageType = {
  _id: string;
  sid: string;
  primaryKey?: string;
  referenceId?: number;
  text: string;
  textLanguage: LANGUAGE;
  translations?: Record<string, string>;
  aiGenerated?: Prediction[];
  isEdited?: boolean;
  isHidden?: boolean;
  type: MESSAGE_TRANSACTION_TYPE;
  channel?: MESSAGE_CHANNEL;
  attachments?: AttachmentType[] | null;
  status: MESSAGE_STATUS;
  smsStatusDetail: string;
  sentBy: string;
  senderName: string;
  counterPartyId: string;
  taskId: string;
  clientId: string;
  metadata?: METADATA_MESSAGE;
  responseTime?: number;
  hasResponded?: boolean;
  messageAt: Date;
  createdAt: Date;
  updatedAt: Date;
  assignedStrategyData?: AssignedStrategyData;
  slaBreached?: boolean;
};

export enum MESSAGE_TRANSACTION_TYPE {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
  INFO = 'INFO',
  NOTE = 'NOTE',
  CLIENT_NOTE = 'CLIENT_NOTE',
  WARNING = 'WARNING',
  AI_GENERATED = 'AI_GENERATED',
}

export enum MESSAGE_STATUS {
  QUEUED = 'queued',
  SENDING = 'sending',
  SENT = 'sent',
  FAILED = 'failed',
  DELIVERY_FAILED = 'delivery_failed',
  EXPIRED = 'expired',
  DELIVERED = 'delivered',
  UNDELIVERED = 'undelivered',
  RECEIVING = 'receiving',
  RECEIVED = 'received',
  ACCEPTED = 'accepted',
  SCHEDULED = 'scheduled',
  READ = 'read',
  PARTIALLY_DELIVERED = 'partially_delivered',
  CANCELED = 'canceled',
  NOP = 'nop',
}

export type METADATA_MESSAGE = {
  template: {
    id: string;
    name: string;
    parametersArray: string[];
  };
  messageDeliveryType?: MESSAGE_DELIVERY_TYPE;
};

export enum MESSAGE_CHANNEL {
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
}
