const TranscriptIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='42' height='42' viewBox='0 0 42 42' fill='none'>
      <rect
        x='1.5249'
        y='1.46697'
        width='39.0845'
        height='39.0845'
        rx='19.5422'
        stroke='#CECECE'
        strokeWidth='1.41551'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.2227 13.0844H24.0564C24.1169 13.0844 24.1772 13.0883 24.237 13.096C26.6117 13.2621 28.4556 15.2328 28.4636 17.6132V24.4063C28.4549 26.9127 26.418 28.9387 23.9116 28.934H18.2227C15.7158 28.9387 13.6788 26.9121 13.6707 24.4052V17.6132C13.6788 15.1063 15.7158 13.0797 18.2227 13.0844Z'
        stroke='#CECECE'
        strokeWidth='1.41551'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.6518 16.3907L17.0763 17.6586C16.9241 17.8037 16.8385 18.0051 16.8397 18.2153C16.8409 18.4255 16.9288 18.6259 17.0827 18.7692L18.1002 19.9938L22.3542 25.109C22.4251 25.1914 22.5201 25.2494 22.6258 25.2749L24.7391 25.7578C25.0185 25.7736 25.2618 25.5688 25.2938 25.2907L25.1955 23.2345C25.1883 23.129 25.1458 23.0289 25.0751 22.9503L20.9193 17.9524L19.7993 16.6073C19.5296 16.2503 19.033 16.1566 18.6518 16.3907Z'
        stroke='#CECECE'
        strokeWidth='1.41551'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M28.4636 18.3632C28.8545 18.3632 29.1714 18.0464 29.1714 17.6555C29.1714 17.2646 28.8545 16.9477 28.4636 16.9477V18.3632ZM24.237 17.6555H23.5293C23.5293 18.0464 23.8462 18.3632 24.237 18.3632V17.6555ZM24.9448 13.0961C24.9448 12.7052 24.6279 12.3883 24.237 12.3883C23.8462 12.3883 23.5293 12.7052 23.5293 13.0961H24.9448ZM21.6165 18.078C21.6836 17.6929 21.4258 17.3264 21.0407 17.2593C20.6556 17.1922 20.2891 17.45 20.222 17.8351L21.6165 18.078ZM18.2047 19.2906C17.8181 19.2328 17.4579 19.4994 17.4002 19.886C17.3424 20.2726 17.609 20.6328 17.9956 20.6906L18.2047 19.2906ZM28.4636 16.9477H24.237V18.3632H28.4636V16.9477ZM24.9448 17.6555V13.0961H23.5293V17.6555H24.9448ZM20.222 17.8351C20.0561 18.7874 19.1608 19.4334 18.2047 19.2906L17.9956 20.6906C19.7116 20.9469 21.3188 19.7873 21.6165 18.078L20.222 17.8351Z'
        fill='#CECECE'
      />
    </svg>
  );
};

export default TranscriptIcon;
