import { io, Socket } from 'socket.io-client';

import { WebSocketMessageType } from '@finance-ops/types';
import { WebsocketMessage } from 'libs/types/src/websocket/WebsocketMessage';
import Router from 'next/router';
import { v4 as uuidv4 } from 'uuid';
import { Track } from '../@core/track';
import authConfig from '../configs/auth';
import { queueLog } from '../utils/pglite/http-interceptor';
import { safeLocalStorage } from 'apps/webapp/src/hooks/useLocalStorage';
import { processResponse } from '../utils/pglite/utils';

let socket: Socket | null = null;
let existingIntervalId: NodeJS.Timer | null = null;

// Initialize messageHandlers with empty object
const messageHandlers: Record<WebSocketMessageType, (payload: any) => void> = Object.create(null);
const inMemoryMessages: Record<string, WebsocketMessage<any>> = {};

const processInMemoryMessages = () => {
  if (existingIntervalId) {
    clearInterval(existingIntervalId);
  }

  existingIntervalId = setInterval(() => {
    Object.entries(inMemoryMessages).forEach(([id, message]) => {
      if (messageHandlers[message.type]) {
        messageHandlers[message.type](message.payload);
        delete inMemoryMessages[id];
      }
    });
  }, 2000);
};

export const setupWebsocket = (callback?: () => void, accessToken?: string) => {
  if (socket?.connected) {
    return;
  } else if (socket) {
    socket?.disconnect();
  }
  if (typeof window !== 'undefined') {
    const storedToken = accessToken || safeLocalStorage.getItem(authConfig.storageTokenKeyName);
    socket = io(process.env.NEXT_PUBLIC_WEBSOCKET_URL as string, { auth: { accessToken: storedToken } });

    socket.on('connect', () => {
      if (callback) callback();
      processInMemoryMessages();
    });

    socket.on('message', message => {
      const { type, payload, requestId } = message as WebsocketMessage<any>;
      const payloadStr = JSON.stringify(payload);
      const { truncated, size } = processResponse(payloadStr);

      queueLog({
        type: 'websocket',
        method: `${type}`,
        direction: 'received',
        websocket_payload: truncated,
        response_size: size,
        timestamp: new Date().toISOString(),
        url: process.env.NEXT_PUBLIC_WEBSOCKET_URL,
        request_id: requestId,
      });

      if (messageHandlers[type]) {
        inMemoryMessages[uuidv4()] = message;
      } else {
        console.log('Unhandled message type:', type);
      }
    });
    // Add redirect to login on disconnect
    socket.on('disconnect', (reason: any) => {
      if (existingIntervalId) {
        clearInterval(existingIntervalId);
      }
      // Avoid redirecting on web page refresh / closing the browser window
      if (reason === 'io server disconnect') {
        console.log('Redirecting to login page, because ->', reason);
        Track.getInstance().redirectedToLoginPage({ reason });
        Router.push('/login');
      }
    });
  }
};

export const disconnectWebsocket = () => {
  if (socket) {
    socket.disconnect();
  }
  if (existingIntervalId) {
    clearInterval(existingIntervalId);
  }
};

export const addMessageHandler = (type: WebSocketMessageType, handler: (payload: any) => void) => {
  messageHandlers[type] = handler;
};

export const sendMessage = (type: WebSocketMessageType, payload?: any) => {
  const requestId = uuidv4();

  queueLog({
    type: 'websocket',
    method: type.toUpperCase(),
    direction: 'sent',
    websocket_payload: JSON.stringify(payload),
    timestamp: new Date().toISOString(),
    url: process.env.NEXT_PUBLIC_WEBSOCKET_URL,
    request_id: requestId,
  });

  socket?.emit('message', {
    type,
    payload,
    requestId,
  });
};

export { socket };
