import { NOTIFICATION_INDICATOR_TASK_EVENTS } from '@finance-ops/constants';
import {
  NOTIFICATION_STATUS,
  NotificationGetAllDtoType,
  NotificationGetPreviousDtoType,
  NotificationUpdateManyReadRequestType,
  WebSocketMessageType,
} from '@finance-ops/types';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../store';
import {
  fetchNotifications,
  fetchPreviousNotifications,
  notificationsSelectors,
  selectAllReadNotifications,
  selectAllUnreadNotifications,
  selectUnHydratedNotificationIds,
} from '../store/notification';
import { getDatePastDays } from '../utils/date-utils';
import { sendMessage } from '../websocket/WebSocketClient';

export const useNotifications = () => {
  const dispatch = useDispatch<AppDispatch>();
  const allNotifications = useSelector(notificationsSelectors.selectAll);
  const allUnreadNotifications = useSelector(selectAllUnreadNotifications);
  const allReadNotifications = useSelector(selectAllReadNotifications);
  const unHydratedNotificationIds = useSelector(selectUnHydratedNotificationIds);

  const updateNotificationsAsRead = useCallback((ids: string[]) => {
    if (ids.length === 0) {
      return;
    }
    const notificationReadRequest: NotificationUpdateManyReadRequestType = { ids };
    sendMessage(WebSocketMessageType.NOTIFICATION_UPDATE_MANY_READ, notificationReadRequest);
  }, []);

  const getNotifications = useCallback(
    async (userId: string) => {
      try {
        const requestNotifications: NotificationGetAllDtoType = {
          userId,
          pageNumber: 0,
          pageSize: 100,
          status: NOTIFICATION_STATUS.UNREAD,
          events: NOTIFICATION_INDICATOR_TASK_EVENTS,
        };
        dispatch(fetchNotifications(requestNotifications));
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
      }
    },
    [dispatch],
  );

  const getPreviousNotifications = useCallback(
    debounce(async (userId: string, fromDate?: Date) => {
      try {
        if (fromDate && new Date(fromDate).getTime() < getDatePastDays(3).getTime()) {
          return;
        }
        const requestNotifications: NotificationGetPreviousDtoType = {
          userId,
          fromDate,
          pageSize: 20,
          status: NOTIFICATION_STATUS.UNREAD,
          events: NOTIFICATION_INDICATOR_TASK_EVENTS,
        };
        dispatch(fetchPreviousNotifications(requestNotifications));
      } catch (error) {
        console.error('Failed to fetch previous notifications:', error);
      }
    }, 5000),
    [dispatch],
  );

  const getTaskIdsFromNotificationIds = useCallback(
    (notificationIds: string[]) => {
      return allNotifications
        .filter(notification => notificationIds.includes(notification.id))
        .map(notification => notification.data?.tasks?.id);
    },
    [allNotifications],
  );

  return {
    allNotifications,
    allReadNotifications,
    allUnreadNotifications,
    updateNotificationsAsRead,
    getNotifications,
    getPreviousNotifications,
    unHydratedNotificationIds,
    getTaskIdsFromNotificationIds,
  };
};
