import { UserDocumentType, UserType } from '@finance-ops/types';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import api from '../../httpClient/api';
import { baseQuery } from './baseQuery';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQuery,
  tagTypes: ['User'],
  endpoints: builder => ({
    createUser: builder.mutation<UserType, Partial<UserDocumentType>>({
      query: user => ({
        url: api.users,
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['User'],
    }),
    getUser: builder.query<UserType, { id: string }>({
      query: ({ id }) => ({
        url: `${api.users}/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, { id }) => [{ type: 'User', id }],
    }),
    updateUser: builder.mutation<UserType, { user: Partial<UserDocumentType>; id: string }>({
      query: ({ user, id }) => ({
        url: `${api.users}/${id}`,
        method: 'PATCH',
        body: user,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'User', id }],
    }),
    deleteUser: builder.mutation<UserType, { id: string }>({
      query: ({ id }) => ({
        url: `${api.users}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'User', id }],
    }),
  }),
});

export const { useCreateUserMutation, useUpdateUserMutation, useDeleteUserMutation, useGetUserQuery } = usersApi;
