import { COLLECTIONS, EVENT_TYPE, NOTIFICATION_INDICATOR_TASK_EVENTS } from '@finance-ops/constants';
import {
  ClientType,
  NOTIFICATION_STATUS,
  NotificationGetAllDtoType,
  NotificationGetPreviousDtoType,
  NotificationType,
} from '@finance-ops/types';
import {
  EntityState,
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '.';
import { httpGet } from '../httpClient';
import api from '../httpClient/api';

export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async (request: NotificationGetAllDtoType) => {
    try {
      const response = await httpGet(api.notifications, request);
      return response.data;
    } catch (error) {
      console.error('Error fetching notifications', error);
    }
  },
);

export const fetchPreviousNotifications = createAsyncThunk(
  'notifications/fetchPreviousNotifications',
  async (request: NotificationGetPreviousDtoType) => {
    try {
      const response = await httpGet(api.notificationPrevious, request);
      return response.data;
    } catch (error) {
      console.error('Error fetching notifications', error);
    }
  },
);

const notificationEntityAdapter = createEntityAdapter<NotificationType>({
  selectId: entity => entity.id,
  sortComparer: (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
});

interface NotificationState extends EntityState<NotificationType> {
  hydratedNotificationIds: string[];
}

const initialState: NotificationState = {
  ...notificationEntityAdapter.getInitialState(),
  hydratedNotificationIds: [],
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: notificationEntityAdapter.setAll,
    deleteOneNotification: notificationEntityAdapter.removeOne,
    upsertOneNotification: notificationEntityAdapter.upsertOne,
    upsertManyNotifications: notificationEntityAdapter.upsertMany,

    addHydratedNotificationIds: (state, action: PayloadAction<string[]>) => {
      const newIds = action.payload.filter(id => !state.hydratedNotificationIds.includes(id));
      state.hydratedNotificationIds.push(...newIds);
    },
    resetNotifications: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      if (action.payload?.length >= 1) notificationEntityAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(fetchPreviousNotifications.fulfilled, (state, action) => {
      if (action.payload?.length >= 1) notificationEntityAdapter.upsertMany(state, action.payload);
    });
  },
});

export const notificationsSelectors = notificationEntityAdapter.getSelectors((state: RootState) => state.notification);

export const {
  setNotifications,
  deleteOneNotification,
  upsertOneNotification,
  upsertManyNotifications,
  addHydratedNotificationIds,
  resetNotifications,
} = notificationSlice.actions;

export const selectUnHydratedNotificationIds = createSelector(
  notificationsSelectors.selectIds,
  (state: RootState) => state.notification.hydratedNotificationIds,
  (notificationIds, hydratedNotificationIds) => {
    return notificationIds.filter(id => !hydratedNotificationIds.includes(id.toString())).map(id => id.toString());
  },
);

export const selectNotificationsByUserId = createSelector(
  [notificationsSelectors.selectAll, (_, userId) => userId],
  (notificationsAll, userId) => {
    const notifications: NotificationType[] = notificationsAll.filter(
      notification => notification !== undefined && notification.userId === userId,
    );
    return notifications ?? [];
  },
);

export const selectUnreadMessageNotificationsByCustomerId = createSelector(
  [notificationsSelectors.selectAll, (_, customerId) => customerId],
  (notificationsAll: NotificationType[], customerId) => {
    const notifications: NotificationType[] = notificationsAll.filter(
      notification =>
        (notification.event === EVENT_TYPE.MESSAGE_INBOUND ||
          notification.event === EVENT_TYPE.NOTE_CREATED ||
          notification.event === EVENT_TYPE.TASK_UPDATE_ASSIGNED_AGENT) &&
        notification.status === NOTIFICATION_STATUS.UNREAD &&
        (notification.data?.messages?.counterPartyId === customerId ||
          notification.data?.customers?._id.toString() === customerId),
    );
    return notifications ?? [];
  },
);

export const selectUnreadMessageNotificationsByThreadId = createSelector(
  [notificationsSelectors.selectAll, (_, threadId) => threadId],
  (notificationsAll: NotificationType[], threadId) => {
    const notifications: NotificationType[] = notificationsAll.filter(
      notification =>
        notification.event === EVENT_TYPE.EMAIL_RECEIVED &&
        notification.status === NOTIFICATION_STATUS.UNREAD &&
        notification.data?.emails?.threadId === threadId,
    );
    return notifications ?? [];
  },
);

export const selectUnreadMessageNotificationsCountForClients = createSelector(
  notificationsSelectors.selectAll,
  (state: RootState, clients: ClientType[]) => clients,
  (notificationsAll: NotificationType[], clients: ClientType[]) => {
    const totalCountByClientId: { [clientId: string]: number } = {};
    for (const client of clients) {
      totalCountByClientId[client.id] = notificationsAll.filter(
        notification =>
          NOTIFICATION_INDICATOR_TASK_EVENTS.includes(notification.event) &&
          notification.status === NOTIFICATION_STATUS.UNREAD &&
          notification.data?.tasks?.clientId === client.id,
      ).length;
    }
    return totalCountByClientId;
  },
);

export const selectAllUnreadNotifications = createSelector(
  notificationsSelectors.selectAll,
  (notificationsAll: NotificationType[]) => {
    return notificationsAll.filter(notification => notification.status === NOTIFICATION_STATUS.UNREAD);
  },
);

export const selectAllReadNotifications = createSelector(
  notificationsSelectors.selectAll,
  (notificationsAll: NotificationType[]) => {
    return notificationsAll.filter(notification => notification.status === NOTIFICATION_STATUS.READ);
  },
);

export const selectUnreadNotifications = createSelector(
  [
    notificationsSelectors.selectAll,
    (
      _,
      params: {
        eventTypes?: EVENT_TYPE[];
        entityId?: string;
        entityType?: COLLECTIONS;
        status?: NOTIFICATION_STATUS[];
      },
    ) => params,
  ],
  (notificationsAll: NotificationType[], params) => {
    return notificationsAll.filter(notification => {
      const eventTypeMatch = !params.eventTypes || params.eventTypes.includes(notification.event);
      const statusMatch = params.status
        ? params.status.includes(notification.status)
        : notification.status === NOTIFICATION_STATUS.UNREAD;
      let entityMatch = true;

      if (params.entityType) {
        if (!params.entityId) {
          entityMatch = false;
        } else {
          switch (params.entityType) {
            case COLLECTIONS.CUSTOMERS:
              entityMatch = params.entityId === notification.data?.customers?._id.toString();
              break;
            case COLLECTIONS.TASK:
              entityMatch = params.entityId === notification.data?.tasks?.id;
              break;
            case COLLECTIONS.ACTION:
              entityMatch = params.entityId === notification.data?.actions?.id;
              break;
            case COLLECTIONS.EMAIL:
              entityMatch = params.entityId === notification.data?.emails?.threadId;
              break;
            default:
              entityMatch = false;
          }
        }
      }

      return eventTypeMatch && statusMatch && entityMatch;
    });
  },
);

export default notificationSlice.reducer;
