const CallNotesIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='29' height='30' viewBox='0 0 29 30' fill='none'>
      <rect x='0.822021' y='1.19421' width='27.6115' height='27.6115' rx='13.8058' stroke='#CECECE' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.6183 9.40143H16.7395C16.7823 9.40143 16.8249 9.40417 16.8672 9.40965C18.5447 9.52696 19.8474 10.9192 19.853 12.6008V17.3999C19.8469 19.1706 18.408 20.6018 16.6373 20.5985H12.6183C10.8473 20.6018 9.40823 19.1701 9.40247 17.3991V12.6008C9.40823 10.8298 10.8473 9.39813 12.6183 9.40143Z'
        stroke='#6461F3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9214 11.7372L11.8084 12.6329C11.7009 12.7354 11.6404 12.8777 11.6412 13.0262C11.6421 13.1747 11.7042 13.3163 11.8129 13.4175L12.5317 14.2826L15.537 17.8963C15.5871 17.9545 15.6542 17.9955 15.7288 18.0135L17.2218 18.3546C17.4192 18.3658 17.5911 18.2211 17.6137 18.0247L17.5442 16.5721C17.5391 16.4975 17.5092 16.4268 17.4592 16.3713L14.5233 12.8405L13.732 11.8902C13.5415 11.638 13.1907 11.5718 12.9214 11.7372Z'
        stroke='#6461F3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.853 13.1307C20.1291 13.1307 20.353 12.9068 20.353 12.6307C20.353 12.3546 20.1291 12.1307 19.853 12.1307V13.1307ZM16.8671 12.6307H16.3671C16.3671 12.9068 16.5909 13.1307 16.8671 13.1307V12.6307ZM17.3671 9.40969C17.3671 9.13354 17.1432 8.90969 16.8671 8.90969C16.5909 8.90969 16.3671 9.13354 16.3671 9.40969H17.3671ZM15.0158 12.9292C15.0631 12.6572 14.881 12.3982 14.609 12.3508C14.3369 12.3035 14.078 12.4856 14.0306 12.7576L15.0158 12.9292ZM12.6055 13.7859C12.3324 13.7451 12.0779 13.9334 12.0371 14.2065C11.9963 14.4796 12.1846 14.7341 12.4577 14.7749L12.6055 13.7859ZM19.853 12.1307H16.8671V13.1307H19.853V12.1307ZM17.3671 12.6307V9.40969H16.3671V12.6307H17.3671ZM14.0306 12.7576C13.9134 13.4304 13.2809 13.8868 12.6055 13.7859L12.4577 14.7749C13.67 14.956 14.8054 14.1368 15.0158 12.9292L14.0306 12.7576Z'
        fill='#6461F3'
      />
    </svg>
  );
};

export default CallNotesIcon;
