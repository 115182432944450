import { httpPost } from '../../httpClient';

// TODO: Remove this once we have a proper way to get the public key
const publicKey = 'BPRLZPp8piZuNVb-Orng7DT_ojeYIUXCmpfwhchBV4YbV_mx1SjSIRRibNCRLG583BsFDVuIm3Brc6PhT5SDoDs';

export const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      await navigator.serviceWorker.register('/service-worker.js');
      console.log('Service Worker registered.');
    } catch (error) {
      console.error('Service Worker registration failed:', error);
    }
  }
};

export const requestNotificationPermission = async (): Promise<void> => {
  if (Notification.permission === 'default') {
    const permission = await Notification.requestPermission();
    if (permission !== 'granted') {
      throw new Error('Permission not granted for notifications.');
    }
  }
};

export const subscribeToPushNotifications = async (): Promise<PushSubscription | null> => {
  if (!publicKey) {
    throw new Error('VAPID public key is not set.');
  }
  const registration = await navigator.serviceWorker.ready;
  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(publicKey),
  });

  return subscription;
};

// Helper to convert VAPID public key
const urlBase64ToUint8Array = (base64String: string): Uint8Array => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  return new Uint8Array([...rawData].map(char => char.charCodeAt(0)));
};

export const setupPushNotifications = async () => {
  try {
    await registerServiceWorker();
    await requestNotificationPermission();
    const subscription = await subscribeToPushNotifications();

    if (subscription) {
      await httpPost('/notifications/subscribe', subscription, {
        'Content-Type': 'application/json',
      });
      console.log('User subscribed to push notifications:', subscription);
    }
  } catch (error) {
    console.error('Push Notification setup failed:', error);
  }
};
