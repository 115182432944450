import { Types } from 'mongoose';
import { AttachmentType } from '../common';
import { TASK_MODE } from '../task';
import { AssignedStrategyData } from '../common/Strategy';

export type TransactionType = {
  id: string;
  customerId: Types.ObjectId;
  clientId: Types.ObjectId;
  invoiceId?: Types.ObjectId;
  sourceId?: string;
  gatewayId?: string;
  amount: number;
  date: Date;
  paymentGatewayInitiatedDate?: Date;
  paymentGatewayCompletedDate?: Date;
  paymentMethod?: PaymentMethod;
  paymentGateway?: PaymentGateway;
  paymentStatus?: PaymentStatusTypes;
  syncStatus: PaymentSyncStatusType;
  currency?: string;
  gatewayMetadata?: any;
  syncMetadata?: any;
  isDeleted?: boolean;
  paymentStatusReason?: any;
  syncStatusReason?: any;
  taskMode?: string;
  assignedUserId?: Types.ObjectId;
  taskSync?: boolean;
  attachments?: AttachmentType[];
  internalAssignedUserId?: Types.ObjectId;
  externalAssignedUserId?: Types.ObjectId;
  createdBy?: string;
  updatedBy?: string;
  dispute?: any;
  timeline?: TransactionTimelineType[];
  dueAmountBeforeTransaction?: number;
  dueDateBeforeTransaction?: Date;
  gatewayCharge?: number;
  internalCharge?: number;
  totalCharge?: number;
  customerName?: string;
  assignedStrategyData?: AssignedStrategyData;
  p2pId?: Types.ObjectId;
  paymentPlanId?: Types.ObjectId;
};

// Utility type to convert Types.ObjectId to string and Types.ObjectId | undefined to string | undefined
type ConvertObjectIdToString<T> = {
  [K in keyof T]: T[K] extends Types.ObjectId
    ? string
    : T[K] extends Types.ObjectId | undefined
    ? string | undefined
    : T[K];
};

// New type extending TransactionType with ObjectId fields converted to string
export type RenderedTransactionType = ConvertObjectIdToString<TransactionType>;

export type PopulatedTransactionType = {
  id: string;
  customerId: string;
  customerName: string;
  customerSourceId: string;
  invoiceNumber: string;
  sourceId: string;
  gatewayId: string;
  date: Date | string;
  amount: number | string;
  paymentMethod: PaymentMethod;
  paymentStatus: PaymentStatusTypes;
  paymentGateway: PaymentGateway;
  paymentInitiatedDate: Date | string;
  invoiceId: string;
  paymentDate: string;
  attachments: AttachmentType[];
  taskMode: TASK_MODE;
  assignedUserId: string;
  internalAssignedUserId: string;
  externalAssignedUserId: string;
  timeline: TransactionTimelineType[];
  totalCharge: number;
  netAmount: number;
};

export type TaskModeTotalsType = {
  totalAmount: number;
  taskMode: TASK_MODE;
};

export type TransactionsPageResponseType = {
  transactions: PopulatedTransactionType[];
  taskModeTotals: TaskModeTotalsType[];
};

export enum PaymentStatusTypes {
  INITIATED = 'INITIATED',
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  REFUNDED = 'REFUNDED',
  REVERSED = 'REVERSED',
  DISPUTED = 'DISPUTED',
  DISPUTE_UPDATED = 'DISPUTE_UPDATED',
  DISPUTE_WON = 'DISPUTE_WON',
  DISPUTE_LOST = 'DISPUTE_LOST',
}

export enum PaymentMethod {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  PREPAID = 'PREPAID',
  BANK_TRANSFER = 'BANK_TRANSFER',
  OTHERS = 'OTHERS',
}

export enum PaymentGateway {
  STRIPE = 'STRIPE',
  NUVEI = 'NUVEI',
  OTHER = 'OTHER',
}

export enum PaymentSyncStatusType {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export type TransactionTimelineFailureDetailsType = {
  type?: string;
  code?: string;
  message?: string;
};

export type TransactionTimelineType = {
  internalCharge: number;
  externalCharge: number;
  totalCharge: number;
  date: Date;
  status: PaymentStatusTypes;
  timestamp: Date;
  refundAmount?: number;
  amountCaptured?: number;
  disputeReason?: string;
  disputeStatus?: string;
  evidenceDetails?: any;
  failureDetails?: TransactionTimelineFailureDetailsType;
};
