// ** React Imports
import { useState } from 'react';

import { useRouter } from 'next/router';
// ** MUI Imports
import Box, { BoxProps } from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';

// ** Icon Imports
import Icon from 'apps/webapp/src/@core/components/icon';

// ** Theme Config Import
import themeConfig from 'apps/webapp/src/configs/themeConfig';

// ** Type Import
import { LayoutProps } from 'apps/webapp/src/@core/layouts/types';

// ** Components
import Customizer from 'apps/webapp/src/@core/components/customizer';
import ScrollToTop from 'apps/webapp/src/@core/components/scroll-to-top';
import Footer from './components/shared-components/footer';
import AppBar from './components/vertical/appBar';
import Navigation from './components/vertical/navigation';

const VerticalLayoutWrapper = styled('div')({
  height: '100%',
  display: 'flex',
});

const MainContentWrapper = styled(Box)<BoxProps>({
  flexGrow: 1,
  minWidth: 0,
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
});

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  transition: 'padding .25s ease-in-out',
}));

const VerticalLayout = (props: LayoutProps) => {
  // ** Props
  const { hidden, settings, children, scrollToTop, footerProps, contentHeightFixed, verticalLayoutProps } = props;

  // ** Vars
  const { skin, contentWidth } = settings;
  const navigationBorderWidth = skin === 'bordered' ? 1 : 0;
  const { navigationSize, disableCustomizer, collapsedNavigationSize } = themeConfig;
  const navWidth = navigationSize;
  const collapsedNavWidth = collapsedNavigationSize;

  // ** States
  const [navVisible, setNavVisible] = useState<boolean>(false);

  // ** Toggle Functions
  const toggleNavVisibility = () => setNavVisible(!navVisible);
  const router = useRouter();

  const isChatBetaPage = router.pathname === '/chat-beta';
  const isOnbaordingPage = router.pathname === '/onboarding';
  const isSetPasswordPage = router.pathname === '/set-password';
  const isStrategyPage = router.pathname === '/strategy-builder';
  const isWorkflow = router.pathname === '/workflow';
  const isDashboard = router.pathname === '/dashboard';
  const isNewTeamOverViewPage = router.pathname === '/team-overview-2';

  const noDefaultSpacing = isChatBetaPage || isStrategyPage || isWorkflow || isDashboard || isNewTeamOverViewPage;

  return (
    <>
      <VerticalLayoutWrapper
        className='layout-wrapper'
        sx={{
          height: {
            xs: '100vh',
            sm: '100%',
          },
          overflow: 'hidden',
        }}
      >
        {/* Navigation Menu */}
        <Navigation
          navWidth={navWidth}
          navVisible={navVisible}
          setNavVisible={setNavVisible}
          collapsedNavWidth={collapsedNavWidth}
          toggleNavVisibility={toggleNavVisibility}
          navigationBorderWidth={navigationBorderWidth}
          navMenuContent={verticalLayoutProps.navMenu.content}
          navMenuBranding={verticalLayoutProps.navMenu.branding}
          menuLockedIcon={verticalLayoutProps.navMenu.lockedIcon}
          verticalNavItems={verticalLayoutProps.navMenu.navItems}
          navMenuProps={verticalLayoutProps.navMenu.componentProps}
          menuUnlockedIcon={verticalLayoutProps.navMenu.unlockedIcon}
          afterNavMenuContent={verticalLayoutProps.navMenu.afterContent}
          beforeNavMenuContent={verticalLayoutProps.navMenu.beforeContent}
          {...props}
        />

        <MainContentWrapper
          className='layout-content-wrapper'
          sx={{
            ...(contentHeightFixed && { maxHeight: '100vh' }),
            backgroundColor: 'background.default',
          }}
        >
          {/* AppBar Component */}
          <AppBar
            toggleNavVisibility={toggleNavVisibility}
            appBarContent={verticalLayoutProps.appBar?.content}
            appBarProps={verticalLayoutProps.appBar?.componentProps}
            {...props}
          />

          {/* Content */}
          <ContentWrapper
            className='layout-page-content'
            sx={{
              ...(contentHeightFixed && {
                overflow: 'hidden',
                '& > :first-of-type': {
                  height: '100%',
                  py: noDefaultSpacing ? 0 : 2,
                  px: noDefaultSpacing ? 0 : 3.5,
                },
              }),
              ...(contentWidth === 'boxed' && {
                mx: 'auto',
                '@media (min-width:1200px)': {
                  maxWidth: '100%',
                  py: noDefaultSpacing ? 0 : 2,
                  px: noDefaultSpacing ? 0 : 3.5,
                },
              }),
              ...((isOnbaordingPage || isSetPasswordPage) && {
                padding: '0 !important',
              }),
            }}
          >
            {children}
          </ContentWrapper>

          {/* Footer Component */}
          <Footer footerStyles={footerProps?.sx} footerContent={footerProps?.content} {...props} />
        </MainContentWrapper>
      </VerticalLayoutWrapper>

      {/* Customizer */}
      {disableCustomizer || hidden ? null : <Customizer />}

      {/* Scroll to top button */}
      {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className='mui-fixed'>
          <Fab color='primary' size='small' aria-label='scroll back to top'>
            <Icon icon='tabler:arrow-up' />
          </Fab>
        </ScrollToTop>
      )}
    </>
  );
};

export default VerticalLayout;
