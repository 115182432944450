import IconNames from 'apps/webapp/src/constants/IconNames';
import ClockIcon from './components/Clock';
import IconStyles from 'apps/webapp/src/types/iconStyles';
import PaidIcon from './components/Paid';
import UnPaidIcon from './components/Unpaid';
import DelayIcon from './components/Delay';
import FilterIcon from './components/Filter';
import EllipseIcon from './components/Ellipse';
import AssignedIcon from './components/Assigned';
import UnAssigned from './components/UnAssigned';
import AutoPilotIcon from './components/AutoPilot';
import WorkListIcon from './components/Worklist';
import CompletedIcon from './components/Completed';
import ReviewIcon from './components/Review';
import User from './components/User';
import DropdownIcon from './components/DropDown';
import PlusIcon from './components/common/Plus';
import MoneyLinkIcon from './components/common/MoneyLink';
import InfoIcon from './components/common/Info';
import ChatIcon from './components/common/Chat';
import EuroIcon from './components/common/Euro';
import Moneybag from './components/common/MoneyBag';
import NoteIcon from './components/common/Note';
import NotePen from './components/common/NotePen';
import SheetIcon from './components/common/Sheet';
import SyncIcon from './components/common/Sync';
import WhatsAppIcon from './components/common/Whatsapp';
import ActivityLogIcon from './components/common/ActivityLog';
import MaintainanceIcon from './components/errors/Maintainence';
import FourOFourIcon from './components/errors/FourOFour';
import ConnectionLostIcon from './components/errors/ConnectionLost';
import UserIcon from './components/common/User';
import LaptopIcon from './components/dashboard/Laptop';
import HunderedIcon from './components/dashboard/Hundered';
import DollarIcon from './components/dashboard/Dollar';
import TrendUpIcon from './components/dashboard/TrendUp';
import TrendDownIcon from './components/dashboard/TrendDown';
import RightArrow from './components/common/RightArrow';
import PriorityFlagIcon from './components/PriorityFlag';
import DownloadIcon from './components/download';
import CheckedFilled from './components/CheckedFilled';
import Congratulations from './components/Congratulations';
import ChangeRequestIcon from './components/ChangeRequestIcon';
import AddCircledIcon from './components/AddCircled';
import NoDataCross from './components/NoDataCross';
import CheckedWithCircle from './components/CheckedWithCircle';
import ConversationIcon from './components/Conversation';
import NegotiationIcon from './components/Negotiation';
import CalenderIcon from './components/Calender';
import OpenedEmailIcon from './components/OpenedEmail';
import SmsIcon from './components/SmsIcon';
import WorkFlowIcon from './components/WorkflowIcon';
import OpenedEmailFilledIcon from './components/OpenedEmailFilled';
import SmsFilledIcon from './components/SmsFilled';
import CalenderFilledIcon from './components/CalenderFilled';
import ReplyAllIcon from './components/email/ReplyAll';
import ReplyIcon from './components/email/Reply';
import ForwardIcon from './components/email/Forward';
import DeleteIcon from './components/DeleteIcon';
import ExpandIcon from './components/ExpandIcon';
import EmailAttchmentIcon from './components/email/EmailAttachment';
import EmailEmojiIcon from './components/email/EmailEmoji';
import EmailImage from './components/email/EmailImage';
import EmailLinkIcon from './components/email/EmailLink';
import EmailBoldIcon from './components/email/EmailBold';
import EmailItalicIcon from './components/email/EmailItalic';
import EmailMoveForwardIcon from './components/email/EmailMoveForwardIcon';
import EmailMoveBackwardIcon from './components/email/EmailMoveBackward';
import SettingIcon from './components/SettingIcon';
import OpenedEmail from './components/OpenedMail';
import WriteNewMailIcon from './components/WriteNewMail';
import StrategyMenuIcon from './components/StrategyMenuIcon';
import HttpsIcon from '@mui/icons-material/Https';
import TimerIcon from './components/TimerIcon';
import RefreshIcon from './components/RefreshIcon';
import MicIcon from './components/call/MicIcon';
import CallIcon from './components/call/CallIcon';
import TranscriptIcon from './components/call/TranscriptIcon';
import MuteMicIcon from './components/call/MuteMicIcon';
import CallCutIcon from './components/call/CallCutIcon';
import TranscriptIconBright from './components/call/TranscriptIconBright';
import AnsweredCallIcon from './components/call/AnsweredCallIcon';
import DeclinedCallIcon from './components/call/DeclinedCallIcon';
import CallNotesIcon from './components/call/CallNotesIcon';
import CallRingingIcon from './components/call/CallRingingIcon';
import CallTranscriptSmallIcon from './components/call/CallTranscriptSmallIcon';
import OngoingCallIcon from './components/call/OngoingCallIcon';
import CallUnMuteMicIcon from './components/call/CallUnMuteMicIcon';
import ThumbsUpIcon from './components/call/ThumbsUpIcon';
import ThumbsDownIcon from './components/call/ThumbsDownIcon';

interface CustomIcon {
  name: string;
  sx?: IconStyles;
}

const CustomIcon = ({ name, sx }: CustomIcon) => {
  switch (name) {
    case IconNames.clock:
      return <ClockIcon {...sx} />;
    case IconNames.paid:
      return <PaidIcon {...sx} />;
    case IconNames.unPaid:
      return <UnPaidIcon {...sx} />;
    case IconNames.delay:
      return <DelayIcon {...sx} />;
    case IconNames.filter:
      return <FilterIcon {...sx} />;
    case IconNames.ellipse:
      return <EllipseIcon {...sx} />;
    case IconNames.user:
      return <User {...sx} />;
    case IconNames.assigned:
      return <AssignedIcon {...sx} />;
    case IconNames.unassigned:
      return <UnAssigned {...sx} />;
    case IconNames.autoPilot:
      return <AutoPilotIcon {...sx} />;
    case IconNames.worklist:
      return <WorkListIcon {...sx} />;
    case IconNames.completed:
      return <CompletedIcon {...sx} />;
    case IconNames.review:
      return <ReviewIcon {...sx} />;
    case IconNames.dropdown:
      return <DropdownIcon {...sx} />;
    case IconNames.common.plus:
      return <PlusIcon {...sx} />;
    case IconNames.common.moneyLink:
      return <MoneyLinkIcon {...sx} />;
    case IconNames.common.info:
      return <InfoIcon {...sx} />;
    case IconNames.common.chat:
      return <ChatIcon {...sx} />;
    case IconNames.common.euro:
      return <EuroIcon {...sx} />;
    case IconNames.common.moneyBag:
      return <Moneybag {...sx} />;
    case IconNames.common.note:
      return <NoteIcon {...sx} />;
    case IconNames.common.notePen:
      return <NotePen {...sx} />;
    case IconNames.common.sheet:
      return <SheetIcon {...sx} />;
    case IconNames.common.sync:
      return <SyncIcon {...sx} />;
    case IconNames.common.whatsApp:
      return <WhatsAppIcon {...sx} />;
    case IconNames.common.activityLog:
      return <ActivityLogIcon {...sx} />;
    case IconNames.maintainenceError:
      return <MaintainanceIcon />;
    case IconNames.fourOFourError:
      return <FourOFourIcon />;
    case IconNames.connectionLostError:
      return <ConnectionLostIcon />;
    case IconNames.common.user:
      return <UserIcon {...sx} />;
    case IconNames.dollar:
      return <DollarIcon />;
    case IconNames.laptop:
      return <LaptopIcon />;
    case IconNames.hundred:
      return <HunderedIcon />;
    case IconNames.trendUp:
      return <TrendUpIcon />;
    case IconNames.trendDown:
      return <TrendDownIcon />;
    case IconNames.rightArrow:
      return <RightArrow />;
    case IconNames.priorityFlag:
      return <PriorityFlagIcon {...sx} />;
    case IconNames.download:
      return <DownloadIcon {...sx} />;
    case IconNames.checkedFilled:
      return <CheckedFilled {...sx} />;
    case IconNames.congratulation:
      return <Congratulations {...sx} />;
    case IconNames.changeRequest:
      return <ChangeRequestIcon />;
    case IconNames.addCircled:
      return <AddCircledIcon {...sx} />;
    case IconNames.noDataCross:
      return <NoDataCross />;
    case IconNames.checkedWithCircle:
      return <CheckedWithCircle />;
    case IconNames.delete:
      return <DeleteIcon {...sx} />;
    case IconNames.conversation:
      return <ConversationIcon />;
    case IconNames.negotiation:
      return <NegotiationIcon />;
    case IconNames.calender:
      return <CalenderIcon {...sx} />;
    case IconNames.openedEmail:
      return <OpenedEmailIcon />;
    case IconNames.sms:
      return <SmsIcon />;
    case IconNames.workflow:
      return <WorkFlowIcon />;
    case IconNames.openedEmaiFilled:
      return <OpenedEmailFilledIcon />;
    case IconNames.smsFilled:
      return <SmsFilledIcon />;
    case IconNames.calenderFilled:
      return <CalenderFilledIcon />;
    case IconNames.replyAll:
      return <ReplyAllIcon {...sx} />;
    case IconNames.reply:
      return <ReplyIcon {...sx} />;
    case IconNames.forward:
      return <ForwardIcon {...sx} />;
    case IconNames.expand:
      return <ExpandIcon {...sx} />;
    case IconNames.emailAttachment:
      return <EmailAttchmentIcon {...sx} />;
    case IconNames.emailEmoji:
      return <EmailEmojiIcon {...sx} />;
    case IconNames.emailImage:
      return <EmailImage {...sx} />;
    case IconNames.emailItalic:
      return <EmailItalicIcon {...sx} />;
    case IconNames.emailBold:
      return <EmailBoldIcon {...sx} />;
    case IconNames.emailLink:
      return <EmailLinkIcon {...sx} />;
    case IconNames.emailForward:
      return <EmailMoveForwardIcon {...sx} />;
    case IconNames.emailBackward:
      return <EmailMoveBackwardIcon {...sx} />;
    case IconNames.settings:
      return <SettingIcon {...sx} />;
    case IconNames.openedMail:
      return <OpenedEmail />;
    case IconNames.writeNewMail:
      return <WriteNewMailIcon />;
    case IconNames.strategyMenuIcon:
      return <StrategyMenuIcon />;
    case IconNames.external:
      return <HttpsIcon sx={{ ...sx }} />;
    case IconNames.timer:
      return <TimerIcon />;
    case IconNames.refresh:
      return <RefreshIcon />;
    case IconNames.callMicIcon:
      return <MicIcon />;
    case IconNames.callIcon:
      return <CallIcon />;
    case IconNames.callTranscriptIcon:
      return <TranscriptIcon />;
    case IconNames.callMuteMicIcon:
      return <MuteMicIcon />;
    case IconNames.callCutIcon:
      return <CallCutIcon />;
    case IconNames.callTranscriptBrightIcon:
      return <TranscriptIconBright />;
    case IconNames.callAnsweredIcon:
      return <AnsweredCallIcon />;
    case IconNames.callUnAnsweredIcon:
      return <DeclinedCallIcon />;
    case IconNames.callTranscriptSmallIcon:
      return <CallTranscriptSmallIcon />;
    case IconNames.callNotesIcon:
      return <CallNotesIcon />;
    case IconNames.callRingingIcon:
      return <CallRingingIcon />;
    case IconNames.ongoingCallIcon:
      return <OngoingCallIcon />;
    case IconNames.thumbsUpIcon:
      return <ThumbsUpIcon />;
    case IconNames.thumbsDownIcon:
      return <ThumbsDownIcon />;
    case IconNames.callUnMuteMicIcon:
      return <CallUnMuteMicIcon />;
    default:
      return null;
  }
};

export default CustomIcon;
