import { UserType } from '@finance-ops/types';
import { TASK_ASSIGNED_USER_KEY_NAME, TASK_STATE_KEY_NAME } from '../TaskType';

export function getTaskKeysForUserType(user: UserType) {
  if (user.role?.isInternal) {
    return {
      taskStateKeyName: TASK_STATE_KEY_NAME.internalState,
      assignedUserKeyName: TASK_ASSIGNED_USER_KEY_NAME.internalAssignedUserId,
    };
  }
  return {
    taskStateKeyName: TASK_STATE_KEY_NAME.externalState,
    assignedUserKeyName: TASK_ASSIGNED_USER_KEY_NAME.externalAssignedUserId,
  };
}
