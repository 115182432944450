import { getWorker } from './worker-manager';
import type { Log } from './types';
import { BATCH_SIZE } from './utils';

export async function saveLog(log: Log): Promise<number> {
  const db = await getWorker();
  const result = await db.query<{ id: number }>(
    `INSERT INTO logs (
      request_id, type, method, url, full_url, query_params, request_body, response_body,
      websocket_payload, status, duration, direction, timestamp, response_size
    ) VALUES ($1, $2, $3, $4, $5, $6, $7, $8, $9, $10, $11, $12, $13, $14)
    RETURNING id`,
    [
      log.request_id,
      log.type,
      log.method,
      log.url,
      log.full_url,
      log.query_params,
      log.request_body,
      log.response_body,
      log.websocket_payload,
      log.status,
      log.duration,
      log.direction,
      log.timestamp,
      log.response_size,
    ],
  );
  return result.rows[0].id;
}

export async function fetchLogs(page = 0): Promise<{ logs: Log[]; total: number }> {
  const db = await getWorker();
  const [result, count] = await Promise.all([
    db.query<Log>('SELECT * FROM logs ORDER BY id LIMIT $1 OFFSET $2', [BATCH_SIZE, page * BATCH_SIZE]),
    db.query<{ count: string }>('SELECT COUNT(*) as count FROM logs'),
  ]);

  return {
    logs: result.rows,
    total: parseInt(count.rows[0].count),
  };
}

export async function clearLogs(): Promise<void> {
  const db = await getWorker();
  await db.query('TRUNCATE TABLE logs');
}
