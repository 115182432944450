import CustomCell from './CustomCell';

interface DurationProps {
  duration: number;
  threshold?: number;
}

function formatDuration(ms: number): string {
  if (ms < 1000) return `${ms}ms`;
  if (ms < 60000) return `${(ms / 1000).toFixed(2)}s`;
  return `${(ms / 60000).toFixed(2)}min`;
}

export default function Duration({ duration, threshold = 1000 }: DurationProps) {
  const isError = duration > threshold;
  const formattedDuration = formatDuration(duration);

  return <CustomCell text={formattedDuration} isError={isError} />;
}
