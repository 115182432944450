const CallIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='41' height='42' viewBox='0 0 41 42' fill='none'>
      <rect x='0.408569' y='0.759216' width='40.5' height='40.5' rx='20.25' fill='#269200' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.5302 15.3674C15.4102 14.4404 15.7212 14.1804 16.0962 14.0594C16.3557 13.9914 16.6279 13.9877 16.8892 14.0484C17.2332 14.1484 17.3242 14.2244 18.4522 15.3484C19.4432 16.3354 19.5422 16.4424 19.6372 16.6354C19.8188 16.9742 19.8472 17.3744 19.7152 17.7354C19.6152 18.0104 19.4732 18.1924 18.8722 18.7954L18.4802 19.1884C18.3772 19.2931 18.353 19.4519 18.4202 19.5824C19.2911 21.0683 20.5267 22.3074 22.0102 23.1824C22.1809 23.2739 22.3912 23.2443 22.5302 23.1094L22.9072 22.7384C23.1402 22.4996 23.3869 22.2745 23.6462 22.0644C24.0533 21.8145 24.5606 21.7922 24.9882 22.0054C25.1972 22.1054 25.2662 22.1674 26.2882 23.1874C27.3422 24.2384 27.3722 24.2714 27.4882 24.5124C27.7065 24.9114 27.7042 25.3946 27.4822 25.7914C27.3692 26.0154 27.3002 26.0964 26.7072 26.7024C26.3492 27.0684 26.0122 27.4024 25.9582 27.4514C25.469 27.8565 24.8413 28.0552 24.2082 28.0054C23.0497 27.8999 21.9284 27.5418 20.9232 26.9564C18.6963 25.7766 16.8006 24.0576 15.4092 21.9564C15.1061 21.5167 14.8367 21.0547 14.6032 20.5744C13.9768 19.5008 13.6533 18.2774 13.6672 17.0344C13.715 16.3842 14.0268 15.7819 14.5302 15.3674Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CallIcon;
