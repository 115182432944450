// ** Redux Imports
import { getUserFullName, USER_STATUS, UserType } from '@finance-ops/types';
import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

const usersEntityAdapter = createEntityAdapter<UserType>({
  selectId: entity => entity.id,
});

interface UsersState {
  ids: string[];
  entities: { [key: string]: UserType };
  showOtp: boolean;
  pendingDeviceId?: string;
  pendingUserId?: string;
  cooldownEndTime?: Date;
}

const initialState: UsersState = {
  ids: [],
  entities: {},
  showOtp: false,
  pendingDeviceId: undefined,
  pendingUserId: undefined,
  cooldownEndTime: undefined,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState: { ...usersEntityAdapter.getInitialState(), ...initialState },
  reducers: {
    setUsers: (state, action: PayloadAction<UserType[]>) => {
      usersEntityAdapter.setMany(state, action.payload);
    },
    deleteUser: usersEntityAdapter.removeOne,
    upsertUser: usersEntityAdapter.upsertOne,
    resetUsers: () => {
      return { ...usersEntityAdapter.getInitialState(), ...initialState };
    },
    setDeviceVerification: (
      state,
      action: PayloadAction<{ show: boolean; deviceId?: string; userId?: string; cooldownEndTime?: Date }>,
    ) => {
      state.showOtp = action.payload.show;
      state.pendingDeviceId = action.payload.deviceId;
      state.pendingUserId = action.payload.userId;
      state.cooldownEndTime = action.payload.cooldownEndTime;
    },
  },
});
export const usersSelectors = usersEntityAdapter.getSelectors<RootState>(state => state.users);

export const selectRespectiveAgents = createSelector(
  [
    usersSelectors.selectAll,
    (_, filteringObject: { isInternalUser: boolean; clientId: string; activeOnly?: boolean }) => filteringObject,
  ],
  (users, filteringObject) => {
    const { isInternalUser, clientId, activeOnly = true } = filteringObject;

    return users?.filter(user => {
      if (activeOnly && user.status !== USER_STATUS.ACTIVE) {
        return false;
      }
      if (isInternalUser) {
        return user?.role?.isInternal ?? false;
      }
      return user.customerId === clientId;
    });
  },
);

export const selectUsers = createSelector(
  [usersSelectors.selectAll, (_, filter: { clientId?: string | null; activeOnly?: boolean }) => filter],
  (users, filter) => {
    const { clientId, activeOnly = true } = filter;
    return users.filter(user => {
      if (activeOnly && user.status !== USER_STATUS.ACTIVE) {
        return false;
      }
      if (clientId) {
        return user.customerId === clientId;
      }
      return true;
    });
  },
);

export const selectAllUsers = usersSelectors.selectAll;

export const selectUserNameById = createSelector([usersSelectors.selectAll, (_, id) => id], (users, id) => {
  const userFound = users.find(user => user.id === id);
  if (userFound) {
    return getUserFullName(userFound);
  }
});

export const { setUsers, resetUsers, deleteUser, upsertUser, setDeviceVerification } = usersSlice.actions;

export const selectOtpState = (state: RootState) => ({
  showOtp: state.users.showOtp,
  pendingDeviceId: state.users.pendingDeviceId,
  pendingUserId: state.users.pendingUserId,
  cooldownEndTime: state.users.cooldownEndTime,
});

export default usersSlice.reducer;
