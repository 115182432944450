export function processResponse(response: string): { truncated: string; size: number } {
  const size = new Blob([response]).size;
  const MAX_SIZE = 100 * 1024; // 100KB

  try {
    if (size <= MAX_SIZE) return { truncated: response, size };

    const parsed = JSON.parse(response);

    if (Array.isArray(parsed)) {
      return {
        truncated: JSON.stringify({
          _type: 'array',
          _total: parsed.length,
          _preview: parsed.slice(0, 10),
          _truncated: `${parsed.length - 10} more items`,
        }),
        size,
      };
    }

    return {
      truncated: JSON.stringify({
        _type: 'object',
        _total: Object.keys(parsed).length,
        _preview: Object.fromEntries(Object.entries(parsed).slice(0, 20)),
        _truncated: `${Object.keys(parsed).length - 20} more fields`,
      }),
      size,
    };
  } catch {
    return {
      truncated:
        size > MAX_SIZE
          ? `${response.substring(0, MAX_SIZE)}\n... (truncated ${(size / 1024).toFixed(1)}KB total)`
          : response,
      size,
    };
  }
}

export const BATCH_SIZE = 50;
export const AUTO_REFRESH_INTERVAL = 1000;
export const DB_NAME = 'idb://devtools_logs';
