const CallUnMuteMicIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='41' height='42' viewBox='0 0 41 42' fill='none'>
      <rect y='0.759277' width='40.5' height='40.5' rx='20.25' fill='white' />
      <path
        d='M20.2499 12.4338C19.5676 12.4338 18.9132 12.7049 18.4308 13.1873C17.9483 13.6698 17.6772 14.3242 17.6772 15.0065V21.0093C17.6772 21.6916 17.9483 22.3459 18.4308 22.8284C18.9132 23.3109 19.5676 23.5819 20.2499 23.5819C20.9322 23.5819 21.5865 23.3109 22.069 22.8284C22.5515 22.3459 22.8225 21.6916 22.8225 21.0093V15.0065C22.8225 14.3242 22.5515 13.6698 22.069 13.1873C21.5865 12.7049 20.9322 12.4338 20.2499 12.4338Z'
        stroke='#6461F3'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.2527 19.2942V21.0093C26.2527 22.6013 25.6202 24.1282 24.4945 25.2539C23.3688 26.3796 21.8419 27.0121 20.2499 27.0121M20.2499 27.0121C18.6578 27.0121 17.131 26.3796 16.0053 25.2539C14.8795 24.1282 14.2471 22.6013 14.2471 21.0093V19.2942M20.2499 27.0121V29.5847'
        stroke='#6461F3'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CallUnMuteMicIcon;
