import { RoleType } from '@finance-ops/types';
import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { RootState } from '.';

interface rolesState extends EntityState<RoleType> {
  loading: boolean;
}

// Create the entity adapter for roles
const rolesAdapter = createEntityAdapter<RoleType>({
  selectId: entity => entity.id.toString(),
  sortComparer: (b, a) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
});

const initialState: rolesState = {
  ...rolesAdapter.getInitialState(),
  loading: false,
};

export const actionsSelectors = rolesAdapter.getSelectors((state: RootState) => state.roles);

export const selectAllRoles = actionsSelectors.selectAll;
export const selectInternalRoles = (state: RootState) => selectAllRoles(state).filter(role => role.isInternal);
export const selectExternalRoles = (state: RootState) => selectAllRoles(state).filter(role => !role.isInternal);

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    upsertManyRoles: rolesAdapter.upsertMany,
  },
});

export const { upsertManyRoles } = rolesSlice.actions;

export default rolesSlice.reducer;
