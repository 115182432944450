import CustomCell from './CustomCell';

interface ResponseSizeProps {
  response: string | null;
  threshold?: number;
}

function getResponseSize(response: string | null, threshold: number): { size: string; isLarge: boolean } {
  if (!response) return { size: '0 B', isLarge: false };

  const bytes = new TextEncoder().encode(response).length;
  const isLarge = bytes > threshold;

  if (bytes < 1024) return { size: `${bytes} B`, isLarge };
  if (bytes < 1024 * 1024) return { size: `${(bytes / 1024).toFixed(1)} KB`, isLarge };
  return { size: `${(bytes / (1024 * 1024)).toFixed(1)} MB`, isLarge };
}

function ResponseSize({ response, threshold = 500 * 1024 }: ResponseSizeProps) {
  const { size, isLarge } = getResponseSize(response, threshold);

  return <CustomCell text={size} isError={isLarge} />;
}

export default ResponseSize;
