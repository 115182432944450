const MicIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='41' height='42' viewBox='0 0 41 42' fill='none'>
      <rect
        x='0.707757'
        y='1.46697'
        width='39.0845'
        height='39.0845'
        rx='19.5422'
        stroke='#CECECE'
        strokeWidth='1.41551'
      />
      <path
        d='M20.25 12.4338C19.5677 12.4338 18.9133 12.7048 18.4309 13.1873C17.9484 13.6697 17.6774 14.3241 17.6774 15.0064V21.0092C17.6774 21.6915 17.9484 22.3459 18.4309 22.8283C18.9133 23.3108 19.5677 23.5818 20.25 23.5818C20.9323 23.5818 21.5867 23.3108 22.0691 22.8283C22.5516 22.3459 22.8226 21.6915 22.8226 21.0092V15.0064C22.8226 14.3241 22.5516 13.6697 22.0691 13.1873C21.5867 12.7048 20.9323 12.4338 20.25 12.4338Z'
        stroke='#CECECE'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.2528 19.2941V21.0092C26.2528 22.6013 25.6204 24.1281 24.4946 25.2538C23.3689 26.3796 21.842 27.012 20.25 27.012M20.25 27.012C18.658 27.012 17.1311 26.3796 16.0054 25.2538C14.8796 24.1281 14.2472 22.6013 14.2472 21.0092V19.2941M20.25 27.012V29.5847'
        stroke='#CECECE'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default MicIcon;
